(<template>
  <article class="chat-abandon">
    <div v-if="userIsInterpreter"
         class="chat-abandon__content">
      <p class="chat-abandon__text">{{ interpreterAbandonText }}</p>
    </div>
    <div v-else
         class="chat-abandon__content">
      <p class="chat-abandon__text">{{ businessAbandonText }}</p>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      },
      fullData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters({
        userIsInterpreter: 'UserInfoStore/userIsInterpreter'
      }),
      businessAbandonText() {
        const template = this.$gettext('%{n} has chosen to not apply.');
        return this.$gettextInterpolate(template, {n: this.fullData && this.fullData.supplierInfo ? this.fullData.supplierInfo.name : ''});
      },
      interpreterAbandonText() {
        return this.$gettext('You chose to not apply.');
      }
    }
  };
</script>

<style scoped>
  .chat-abandon__text {
    margin-bottom: 5px;
    color: #f04;
    color: var(--color-error);
    font-size: 14px;
  }
</style>
