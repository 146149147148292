(<template>
  <article :class="{'in-progress': progress}"
           class="assignment-info is-progress-bar">
    <div v-for="(section, sectionIndex) in filteredSectionsList"
         :key="sectionIndex"
         :class="section.classes"
         class="assignment-info__section">
      <h3 v-if="section.title"
          class="assignment-info__section-title">{{ section.title }}</h3>
      <edit-button v-if="section.editable"
                   :modal-classes="section.modalClasses"
                   :section-name="section.sectionName"
                   :validate-func="section.validateFunc"
                   :fetch-func="section.fetchFunc"
                   class="assignment-info__edit-btn"
                   @fetchsuitableinterpreters="fetchSuitableInterpreters" />
      <div v-for="(group, groupIndex) in getFilteredGroups(section.groups)"
           :key="groupIndex"
           class="assignment-info__inner-sections">
        <edit-button v-if="group.editable"
                     :section-name="group.sectionName"
                     :validate-func="group.validateFunc"
                     :fetch-func="group.fetchFunc"
                     class="assignment-info__edit-btn"
                     :class="group.class"
                     @fetchsuitableinterpreters="fetchSuitableInterpreters" />
        <component :is="component.componentName"
                   v-for="component in getFilteredComponents(group.components)"
                   :key="component.key"
                   :data="component"
                   class="assignment-info__inner-section" />
      </div>
    </div>
    <contract-section v-if="showContractLink" />
  </article>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';
  import AttachmentsSection from '@/components/assignment_components/one_assignment/assignment_info/AttachmentsSection';
  import DataSection from '@/components/assignment_components/one_assignment/assignment_info/DataSection';
  import ChangeNotifySection
    from '@/components/assignment_components/one_assignment/assignment_info/ChangeNotifySection';
  import AssignmentTypeSection
    from '@/components/assignment_components/one_assignment/assignment_info/AssignmentTypeSection';
  import AttendersSection from '@/components/assignment_components/one_assignment/assignment_info/AttendersSection';
  import InterpretationAccountManager from '@/components/assignment_components/one_assignment/assignment_info/InterpretationAccountManager';
  import CancellationPolicy from '@/components/assignment_components/one_assignment/assignment_info/CancellationPolicy';
  import DirectInvite from '@/components/assignment_components/one_assignment/assignment_info/DirectInvite';
  import ContractSection from '@/components/assignment_components/one_assignment/assignment_info/ContractSection';
  import AutoInviteSection from '@/components/assignment_components/one_assignment/assignment_info/AutoInviteSection';
  import InfiniteAutoInviteSection
    from '@/components/assignment_components/one_assignment/assignment_info/InfiniteAutoInviteSection';
  import AutoApproveSection from '@/components/assignment_components/one_assignment/assignment_info/AutoApproveSection';
  import StandBySection from '@/components/assignment_components/one_assignment/assignment_info/StandBySection';
  import JobInfoPrototype from '@/prototypes/JobInfoPrototype';
  import LanguagesSection from '@/components/assignment_components/one_assignment/assignment_info/LanguagesSection';
  import PricingTemplatesSection
    from '@/components/assignment_components/one_assignment/assignment_info/PricingTemplatesSection';
  import AssignmentInfoEditButton
    from '@/components/assignment_components/one_assignment/assignment_info/AssignmentInfoEditButton';
  import SystemLogButton from '@/components/assignment_components/one_assignment/assignment_info/SystemLogButton';
  import SecretaryAssignmentMessageSection from '@/components/assignment_components/one_assignment/assignment_info/SecretaryAssignmentMessageSection';
  import LockJobButton from '@/components/assignment_components/one_assignment/assignment_info/LockJobButton';

  export default {
    components: {
      'auto-invite-section': AutoInviteSection,
      'infinite-auto-invite-section': InfiniteAutoInviteSection,
      'auto-approve-section': AutoApproveSection,
      'standby-section': StandBySection,
      'languages-section': LanguagesSection,
      'data-section': DataSection,
      'change-notify-section': ChangeNotifySection,
      'assignment-type': AssignmentTypeSection,
      'secretary-assignment-message': SecretaryAssignmentMessageSection,
      'attenders-section': AttendersSection,
      'cancellation-policy': CancellationPolicy,
      'attachments-section': AttachmentsSection,
      'account-manager': InterpretationAccountManager,
      'contract-section': ContractSection,
      'direct-invite': DirectInvite,
      'pricing-templates-section': PricingTemplatesSection,
      'edit-button': AssignmentInfoEditButton,
      'system-log-button': SystemLogButton,
      'lock-job-button': LockJobButton
    },
    extends: JobInfoPrototype,
    props: {
      progress: Boolean,
      showAdminSectionOnly: Boolean,
      showEditButton: Boolean
    },
    data() {
      return {
        advancedInfoExpanded: false,
        domain: this.$getString('domainData', 'platformName')
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsEnterpriseMember',
        'userIsNotInterpreter'
      ]),
      ...mapGetters('OneAssignmentStore', [
        'isSecretaryAssignment',
        'customerUseOwnVideoSystem'
      ]),
      ...mapGetters('PostingNotifierStore', [
        'showPricingTemplatesSection'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {},
        contractInfo: (state) => {
          return state.jobCallDetails?.contractInfo?.length ? state.jobCallDetails.contractInfo[0] : [];
        }
      }),
      ...mapGetters('PostingStore/PaymentMethodStore', [
        'defaultPaymentMethodData'
      ]),
      showEditMyInformation() {
        const notVisibleOn = ['finished', 'cancelled', 'needsFeedback'];
        return !notVisibleOn.includes(this.jobStatus) && this.userIsNotInterpreter;
      },
      // common info
      jobId() { return this.$route.params.id; },
      assignmentInfo() { return this.assignmentObj.info || {}; },
      assignmentParticipants() { return this.assignmentObj.participants || []; },
      jobStatus() { return this.assignmentObj.status || ''; },
      canBeLocked() { return this.jobStatus === 'finished' || this.jobStatus === 'needsFeedback'; },
      paymentMethodData() {
        const jobPaymentMethodData = this.assignmentObj.paymentMethod || {};

        return jobPaymentMethodData.id ? jobPaymentMethodData : this.defaultPaymentMethodData;
      },
      paymentMethod() {
        return helpers.getJobInfo.getPaymentMethodName(this, {
          paymentMethodData: this.paymentMethodData
        });
      },
      // common info: job statuses
      isAcceptedAssignment() { return this.jobStatus == 'accepted' || this.jobStatus == 'in_progress'; },

      // interpretation requirements
      assignmentRequirements() {
        return this.assignmentObj.interpretationRequirement || {};
      },
      assignmentContactPerson() { return this.assignmentRequirements.contactAttributes?.person || {}; },
      assignmentContactAddress() { return this.assignmentRequirements.contactAttributes?.address || {}; },
      assignmentType() { return this.assignmentRequirements.sessionType; },
      alternativeLanguage() {
        const alternativeLanguageId = this.assignmentRequirements.alternativeLanguageToId;

        return alternativeLanguageId ? helpers.getJobInfo.jobLanguageName(this, alternativeLanguageId) : '';
      },
      enterpriseDepartment() { return this.assignmentRequirements.department; },
      contactPerson() { return this.assignmentContactPerson.name; },
      contactPhone() { return this.assignmentContactPerson.phone; },
      videoPlatform() { return this.assignmentRequirements.videoPlatform || {}; },
      videoPlatformInstructions() { return this.assignmentRequirements.videoPlatformInstructions || ''; },
      videoPlatformUrl() { return this.assignmentRequirements.videoPlatformUrl || ''; },
      assignmentDialect() { return this.assignmentRequirements.dialect || ''; },

      // process requirements
      assignmentProcessRequirement() { return this.assignmentObj.processRequirement || {}; },
      autoInvite() { return this.assignmentProcessRequirement.autoInviteStatus; },
      infiniteAutoInvite() { return !!this.assignmentProcessRequirement.infiniteAutoInvite; },
      autoUpdateSexAt() { return this.assignmentProcessRequirement.autoUpdateSexAt || ''; },
      autoUpdateDirectAt() { return this.assignmentProcessRequirement.autoUpdateDirectAt || ''; },
      autoUpdateSessionTypeAt() { return this.assignmentProcessRequirement.autoUpdateSessionTypeAt || ''; },
      autoUpdateAlternativeLanguageAt() { return this.assignmentProcessRequirement.autoUpdateAlternativeLanguageAt || ''; },
      autoUpdateToSessionType() { return this.assignmentProcessRequirement.autoUpdateToSessionType || ''; },
      isAutoUpdateSession() { return this.assignmentProcessRequirement.sessionTypeProcessing === 'session_type_auto_update'; },
      isSessionUpdated() {
        let isUpdated = false;
        if (this.isAutoUpdateSession) {
          isUpdated = this.autoUpdateToSessionType === this.assignmentType;
        }
        return isUpdated;
      },

      // assignment info
      copiedJobs() {
        return this.assignmentObj.copiesInfo || [];
      },
      seriesJobs() { return this.assignmentObj.seriesJobs || []; },
      connectedJobs() {
        return this.seriesJobs.filter((job) => job.id != this.jobId) || [];
      },
      blockedSuppliers() { return this.assignmentObj.blockedInterpreters || []; },
      blockedSuppliersNames() { return this.blockedSuppliers.map((supplier) => supplier.name); },
      blockedCities() { return this.assignmentRequirements.blockedCities || []; },
      assignmentIsInPerson() { return this.assignmentType == 'in_person'; },
      assignmentIsVideo() { return this.assignmentType == 'video'; },
      assignmentIsVideoroom() { return this.assignmentType == 'videoroom'; },
      addressIsVisible() { return this.assignmentIsInPerson || this.assignmentIsVideoroom; },
      contactInfoIsVisible() {
        return this.assignmentIsInPerson
          && ((this.isAwardedJob && this.userIsInterpreter) || this.userIsNotInterpreter);
      },
      jobAuthor() { return this.assignmentInfo.owner; },
      clientName() { return this.assignmentInfo.clientName; },
      jobDescription() { return this.assignmentInfo.description; },
      caseNumber() { return this.assignmentInfo.caseNumber; },
      bookingReference() { return this.assignmentInfo.bookingReference; },
      paymentBookingReference() { return this.assignmentInfo.paymentBookingReference; },
      hotlineCallFrom() { return this.assignmentObj.hotlineCallFrom; },
      enterpriseDepartmentName() { return this.enterpriseDepartment ? this.enterpriseDepartment.name : ''; },
      enterpriseDepartmentEmail() { return this.enterpriseDepartment ? this.enterpriseDepartment.email : ''; },
      enterpriseDepartmentPhone() { return this.enterpriseDepartment ? this.enterpriseDepartment.phone_code + this.enterpriseDepartment.phone_number : ''; },
      ccEmails() { return this.assignmentInfo.ccEmails || []; },
      confirmationPhones() { return this.assignmentInfo.confirmationPhones || []; },
      isLockedJob() { return this.assignmentObj.locked; },

      // price template
      activeDemanderPricingTemplate() { return this.assignmentObj.demanderPricingTemplate; },
      activeSupplierPricingTemplate() { return this.assignmentObj.supplierPricingTemplate; },
      activeDemanderTemplateName() { return this.activeDemanderPricingTemplate?.name || ''; },
      activeSupplierTemplateName() { return this.activeSupplierPricingTemplate?.name || ''; },

      // other
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; },

      // showing fields
      showBlockedCitiesSection() {
        return this.$isGodmode();
      },
      showAutoInviteSection() {
        return this.autoInvite && this.autoInvite !== 'locked';
      },
      showInfiniteAutoInviteSection() {
        const autoInviteEnabled = this.assignmentProcessRequirement.autoInviteEnabled;

        return this.showAutoInviteSection && autoInviteEnabled === 'on';
      },
      showAutoApproveSection() {
        return this.userIsEnterpriseMember
          && ['published', 'applied'].includes(this.jobStatus);
      },
      showStandBySection() {
        return this.userIsEnterpriseMember
          && ['published', 'applied'].includes(this.jobStatus);
      },
      showUpdateGenderText() {
        return this.autoUpdateSexAt && this.$isGodmode();
      },
      showUpdateSessionText() {
        return this.autoUpdateSessionTypeAt
          // if before it reaches auto-update time
          && !this.autoUpdateAfterStartTime(this.autoUpdateSessionTypeAt)
          // if session hasn't been updated manually
          && !this.isSessionUpdated
          // if job hasn't been started
          && ['published', 'applied', 'withdrawn', 'invited'].includes(this.jobStatus)
          && this.$isGodmode();
      },
      showUpdateQualificationText() {
        return this.userIsNotInterpreter
          && this.assignmentRequirements.specificQualificationRequested
          && this.assignmentProcessRequirement.qualificationProcessing
          && this.$isGodmode();
      },
      showUpdateDirectInterpreter() {
        return this.userIsNotInterpreter && this.assignmentProcessRequirement.directProcessing === 'direct_find_another';
      },
      showAutoUpdateDirectInterpreter() {
        return this.userIsNotInterpreter && this.assignmentProcessRequirement.autoCancelWillBeProcessed;
      },
      showSameSupplierText() {
        return this.userIsNotInterpreter
          && this.connectedJobs.length
          && this.assignmentProcessRequirement.seriesJobProcessing === 'same_int';
      },
      showAlternativeLanguageText() {
        return this.userIsNotInterpreter
          && this.alternativeLanguage
          && this.autoUpdateAlternativeLanguageAt
          && this.assignmentProcessRequirement.alternativeLanguageProcessing === 'alternative_language_auto_update'
          && !this.assignmentProcessRequirement.alternativeLanguageActivated
          && this.$isGodmode();
      },
      showContractLink() {
        return this.assignmentObj.signedContract?.pricingTemplates?.length;
      },
      showAttendersSection() { return this.userIsEnterpriseMember && this.userIsNotInterpreter; },
      showDirectInviteNote() {
        return this.userIsInterpreter
          && this.assignmentObj.direct
          && this.jobStatus == 'invited';
      },
      isShowProvider() {
        return this.userIsNotInterpreter
          && this.assignmentObj.provider
          && !this.$isOnDomain(this.assignmentObj.provider);
      },
      showContactExtraInfo() {
        if (this.assignmentIsInPerson && this.assignmentContactAddress.extra) {
          return true;
        } else if (this.assignmentIsVideoroom) {
          return this.userIsInterpreter;
        }
      },
      showAttachmentsSection() {
        if (this.userIsInterpreter) {
          return this.isAcceptedAssignment && this.assignmentObj.attachments && this.assignmentObj.attachments.length;
        } else {
          return this.assignmentObj.attachments && this.assignmentObj.attachments.length;
        }
      },
      showTimeBlock() {
        return this.assignmentRequirements.startTime && this.assignmentRequirements.finishTime && !this.isSecretaryAssignment;
      },
      showDeadlineBlock() {
        return this.assignmentRequirements.deadline && this.isSecretaryAssignment;
      },
      showSecretaryInformation() {
        return this.isSecretaryAssignment
          && (this.userIsNotInterpreter
            || [
              'accepted',
              'finished',
              'needsFeedback'
            ].includes(this.jobStatus)
            || this.$isGodmode());
      },
      showPricingTemplatesSection() {
        return this.$isGodmode();
      },

      // section configs
      sectionsList() {
        return [{
          title: this.$gettextInterpolate(this.$gettext('%{domain} - Admin tools'), {
            domain: this.$getString('domainData', 'platformName')
          }),
          editable: true,
          ...this.adminSectionData,
          sectionName: 'adminInformationSection',
          validateFunc: 'validateAdminSection',
          modalClasses: ['sk-modal--dark'],
          classes: ['assignment-info__section--dark'],
          condition: this.showAdminSectionOnly
        }, {
          title: this.$gettext('Direct booking'),
          classes: ['assignment-info__section--direct'],
          groups: [{
            components: [{
              condition: this.showDirectInviteNote,
              componentName: 'direct-invite',
              key: Math.random()
            }]
          }],
          condition: !this.showAdminSectionOnly
        }, {
          title: this.isSecretaryAssignment ? this.$gettext('Secretary assignment') : this.$gettext('Assignment information'),
          ...this.assignmentInfoSectionData,
          condition: !this.showAdminSectionOnly
        }, {
          title: this.userIsNotInterpreter ? this.$gettext('My information') : this.$gettext('Customer information'),
          editable: this.userIsEnterpriseMember && this.showEditButton && this.showEditMyInformation,
          ...this.myInfoSectionData,
          sectionName: 'personalInformationSection',
          validateFunc: 'validatePersonalInfoSection',
          fetchFunc: 'PostingNotifierStore/fetchDataForPersonalInformationSectionEdit',
          condition: !this.showAdminSectionOnly
        }, {
          sectionName: 'invoiceInformationSection',
          validateFunc: 'validateInvoiceSection',
          title: this.$gettext('Invoice information'),
          editable: this.showEditButton,
          ...this.invoiceSectionData,
          modalClasses: ['sk-modal--invoice'],
          fetchFunc: 'PostingNotifierStore/fetchDataForInvoiceInformationSectionEdit',
          condition: !this.showAdminSectionOnly
        }, {
          groups: [{
            components: [{
              condition: this.userIsNotInterpreter,
              componentName: 'account-manager',
              key: Math.random()
            }]
          }],
          condition: !this.showAdminSectionOnly
        }];
      },
      filteredSectionsList() {
        return this.sectionsList.filter((section) => {
          if (section.condition) {
            const availableComponentsGroup = section.groups.find((group) => {
              return group.components.some((component) => !!component.condition);
            });
            if (availableComponentsGroup) return section;
          }
        });
      },
      adminSectionData() {
        const showAdminSection = this.$isGodmode() && this.userIsNotInterpreter;
        return {
          groups: [{
            components: [{
              condition: showAdminSection && this.assignmentProcessRequirement.sessionTypeProcessing === 'session_type_cancel_not_found',
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                data: this.cancelNotFoundText,
                classes: ['assignment-info__cancel-note']
              }]
            }, {
              condition: showAdminSection && this.assignmentProcessRequirement.directProcessing === 'direct_cancel_said_no',
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                data: this.cancelDirectSaidNoText,
                classes: ['assignment-info__cancel-note']
              }]
            }, {
              condition: showAdminSection && this.assignmentProcessRequirement.genderProcessing === 'gender_cancel_not_found',
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                data: this.cancelGenderNotFoundText,
                classes: ['assignment-info__cancel-note']
              }]
            }, {
              condition: showAdminSection && this.assignmentProcessRequirement.qualificationProcessing === 'qualification_cancel_not_found',
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                data: this.cancelQualificationNotFoundText,
                classes: ['assignment-info__cancel-note']
              }]
            }, {
              condition: showAdminSection && this.assignmentObj.actualCreatedAt,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Booked at'),
                data: this.$moment(this.assignmentObj.actualCreatedAt)
                  .format('DD.MM.YYYY HH:mm')
              }]
            }, {
              condition: showAdminSection,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Booking received via'),
                data: helpers.getJobInfo.jobBookingMechanism(this, this.assignmentObj.bookingMechanism)
              }]
            }, {
              condition: showAdminSection
                && (this.assignmentInfo.manualBookingFeeApplied
                  || this.assignmentInfo.manualEditingFeeApplied
                  || this.assignmentInfo.manualTravelBookingFeeApplied),
              componentName: 'data-section',
              key: Math.random(),
              data: [this.manualBookingFeesObj]
            }, {
              condition: showAdminSection && this.assignmentInfo.confirmationByPhone,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Customer request'),
                data: this.$gettext('This customer wants to receive interpreter details by phone')
              }]
            }, {
              condition: showAdminSection && this.assignmentRequirements.confirmationBy,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Confirm booking by'),
                data: this.$moment(this.assignmentRequirements.confirmationBy)
                  .format('DD.MM.YYYY HH:mm')
              }]
            }, {
              condition: showAdminSection && this.showAutoInviteSection,
              componentName: 'auto-invite-section',
              key: Math.random()
            }, {
              condition: showAdminSection && this.showInfiniteAutoInviteSection,
              componentName: 'infinite-auto-invite-section',
              key: Math.random()
            }, {
              condition: showAdminSection && this.showAutoApproveSection,
              componentName: 'auto-approve-section',
              key: Math.random()
            }, {
              condition: showAdminSection && this.showStandBySection,
              componentName: 'standby-section',
              key: Math.random()
            }, {
              condition: this.activeDemanderTemplateName,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Active Customer template'),
                data: this.activeDemanderTemplateName
              }]
            }, {
              condition: this.activeSupplierTemplateName,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Active Interpreter template'),
                data: this.activeSupplierTemplateName
              }]
            }, {
              condition: this.showPricingTemplatesSection,
              componentName: 'pricing-templates-section',
              key: Math.random()
            }, {
              condition: this.$isGodmode() && !this.isLockedJob && this.canBeLocked,
              componentName: 'lock-job-button',
              key: Math.random()
            }, {
              condition: this.$isGodmode(),
              componentName: 'system-log-button',
              key: Math.random()
            }]
          }]
        };
      },
      assignmentInfoSectionData() {
        return {
          groups: [{
            components: [{
              condition: this.isSecretaryAssignment,
              componentName: 'secretary-assignment-message',
              key: Math.random()
            }]
          }, {
            components: [{
              condition: true,
              componentName: 'data-section',
              key: Math.random(),
              data: [this.publishedAtObj]
            }, {
              condition: this.connectedJobs.length,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Connected assignment(s)'),
                data: this.connectedJobs
                  .map((job) => {
                    return this.getJobLink(job.id, 'assignment-info__delimiter', helpers.getJobInfo.jobId(job));
                  })
                  .join('')
              }]
            }, {
              condition: this.showSameSupplierText,
              componentName: 'change-notify-section',
              key: Math.random(),
              data: [{
                data: helpers.getJobInfo.jobProcessRequirement(this, 'same_int')
              }]
            }, {
              condition: this.assignmentObj.actualCreatedAt,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Booked at'),
                data: this.$moment(this.assignmentObj.actualCreatedAt).format('DD.MM.YYYY HH:mm')
              }]
            }, {
              condition: this.assignmentObj.originalJobId && this.userIsNotInterpreter,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Copied from'),
                data: this.getJobLink(this.assignmentObj.originalJobId)
              }]
            }, {
              condition: this.copiedJobs && this.copiedJobs.length && this.userIsNotInterpreter,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Copied to'),
                data: this.copiedJobs
                  .map((job) => this.getJobLink(job.id, 'assignment-info__delimiter'))
                  .join('')
              }]
            }]
          }, {
            editable: this.showEditButton,
            sectionName: this.isSecretaryAssignment ? 'mainSecretaryAssignmentInformationSection' : 'mainAssignmentInformationSection',
            validateFunc: this.isSecretaryAssignment ? 'validateMainSecretaryAssignmentInfoSection' : 'validateMainAssignmentInfoSection',
            fetchFunc: 'PostingNotifierStore/fetchDataForMainAssignmentInformationSectionEdit',
            components: [{
              condition: true,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Subject'),
                data: this.subject
              }]
            }, {
              condition: true,
              componentName: 'languages-section',
              key: Math.random(),
              data: {
                langFromId: this.assignmentRequirements.languageFromId,
                langToId: this.assignmentRequirements.languageToId
              }
            }, {
              condition: this.assignmentDialect,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Dialect'),
                data: this.assignmentDialect
              }]
            }, {
              condition: this.alternativeLanguage,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Alternative language'),
                data: this.alternativeLanguage
              }]
            }, {
              condition: this.showAlternativeLanguageText,
              componentName: 'change-notify-section',
              key: Math.random(),
              data: [{
                data: this.assignmentStartTimePassed || this.autoUpdateAfterStartTime(this.autoUpdateAlternativeLanguageAt)
                  ? this.$gettext('Alternative language will not be updated automatically')
                  : this.updateAlternativeLanguageTypeText
              }],
              btnText: this.$gettext('Open up alternate language'),
              btnAction: () => { this.downgradeProcessRequirements('alternative_language'); }
            }, {
              condition: this.showTimeBlock,
              componentName: 'data-section',
              key: Math.random(),
              data: [this.jobStartTimeObj, this.jobFinishTimeObj]
            }, {
              condition: this.showDeadlineBlock,
              componentName: 'data-section',
              key: Math.random(),
              data: [this.jobDeadlineObj]
            }, {
              condition: true,
              componentName: 'assignment-type',
              key: Math.random(),
              type: this.assignmentType,
              typeText: this.getJobType(this.assignmentType)
            }, {
              condition: this.assignmentIsVideo && this.assignmentRequirements.videoPlatformUrl,
              componentName: 'data-section',
              key: Math.random(),
              data: [this.jobVideoUrl]
            }, {
              condition: this.showSecretaryInformation && this.assignmentInfo.secretaryContactName,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Name of client / patient to be contacted'),
                data: this.assignmentInfo.secretaryContactName
              }]
            }, {
              condition: this.showSecretaryInformation && this.assignmentInfo.secretaryContactPhoneNumber,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Client\'s / patient\'s telephone number'),
                data: `${this.assignmentInfo.secretaryContactPhoneCode || ''}${this.assignmentInfo.secretaryContactPhoneNumber || ''}`
              }]
            }, {
              condition: this.showSecretaryInformation && this.assignmentInfo.secretaryInfo,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Information for the interpreter'),
                data: this.assignmentInfo.secretaryInfo
              }]
            }, {
              condition: this.showSecretaryInformation && this.assignmentInfo.secretaryCallbackPhoneNumber,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.userIsNotInterpreter
                  ? this.$gettext('The interpreter should call me when finished to')
                  : this.$gettext('You should call customer when finished to'),
                data: `${this.assignmentInfo.secretaryCallbackPhoneCode || ''}${this.assignmentInfo.secretaryCallbackPhoneNumber || ''}`
              }]
            }, {
              condition: this.addressIsVisible,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.$gettext('Address'), data: this.contactAddress}]
            }, {
              condition: this.contactInfoIsVisible,
              componentName: 'data-section',
              key: Math.random(),
              data: [
                {title: this.$gettext('Contact person'), data: this.contactPerson},
                {title: this.$gettext('Contact phone'), data: this.contactPhone}]
            }, {
              condition: this.showContactExtraInfo,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.$gettext('Contact extra info'), data: this.contactExtra}]
            }, {
              condition: this.showUpdateSessionText,
              componentName: 'change-notify-section',
              key: Math.random(),
              data: [{
                data: this.assignmentStartTimePassed || this.autoUpdateAfterStartTime(this.autoUpdateSessionTypeAt)
                  ? this.$gettext('Assignment type will not be updated automatically')
                  : this.updateSessionTypeText
              }],
              btnText: this.changeSessionTypeBtnText,
              btnAction: () => { this.downgradeProcessRequirements('session_type'); }
            }, {
              condition: this.showUpdateDirectInterpreter,
              componentName: 'change-notify-section',
              key: Math.random(),
              data: [{
                data: helpers.getJobInfo.jobProcessRequirement(this, 'direct_find_another')
              }]
            }, {
              condition: this.showAutoUpdateDirectInterpreter,
              componentName: 'change-notify-section',
              key: Math.random(),
              data: [{
                data: this.directAutoUpdateText
              }]
            }, {
              condition: this.qualificationText && this.userIsNotInterpreter,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Qualification level'),
                data: this.qualificationText
              }],
            }, {
              condition: this.showUpdateQualificationText,
              componentName: 'change-notify-section',
              key: Math.random(),
              data: [{
                data: this.assignmentStartTimePassed
                  ? this.assignmentProcessRequirement.qualificationProcessing === 'qualification_auto_update'
                    ? this.$gettext('Qualification requirement will not be updated automatically')
                    : this.$gettext('This assignment will be cancelled if no requested interpreter will be found')
                  : ''
              }],
              btnText: this.$gettext('Turn off qualification requirement'),
              btnAction: () => { this.downgradeProcessRequirements('qualification'); },
              btnInfoText: this.assignmentProcessRequirement.qualificationProcessing === 'qualification_cancel_not_found'
                ? this.$gettext('The customer did not allow qualification requirements to be turned off for this assignment.')
                : this.$gettext('The customer allowed qualification requirements to be turned off for this assignment.')
            }, {
              condition: this.genderText,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.$gettext('Gender'), data: this.genderText}]
            }, {
              condition: this.showUpdateGenderText,
              componentName: 'change-notify-section',
              lighter: true,
              key: Math.random(),
              data: [{
                data: this.assignmentStartTimePassed || this.autoUpdateAfterStartTime(this.autoUpdateSexAt)
                  ? this.$gettext('Gender requirement will not be updated automatically')
                  : this.updateGenderText
              }],
              btnText: this.$gettext('Turn off gender requirement'),
              btnAction: () => { this.downgradeProcessRequirements('gender'); }
            }]
          }, {
            editable: this.showEditButton,
            sectionName: 'attachmentInformationSection',
            class: ['attachment__edit-btn'],
            validateFunc: 'validateSecondaryAssignmentInfoSection',
            components: [{
              condition: this.showAttachmentsSection,
              componentName: 'attachments-section',
              key: Math.random()
            }]
          }, {
            editable: this.showEditButton,
            sectionName: 'secondaryAssignmentInformationSection',
            validateFunc: 'validateSecondaryAssignmentInfoSection',
            components: [{
              condition: this.hotlineCallFrom,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.$gettext('This hotline booking was made using'), data: `+${this.hotlineCallFrom}`}]
            }, {
              condition: this.blockedSuppliers.length && this.userIsNotInterpreter,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.$gettext('Blocked interpreters'), data: this.blockedSuppliersNames.join('<br />')}]
            }, {
              condition: this.blockedCities.length && this.showBlockedCitiesSection,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Blocked cities'),
                data: this.blockedCities
                  .map((city) => `${city.name}, ${city.county}, ${city.country}<br/>`)
                  .join('')
              }]
            }, {
              condition: this.jobDescription,
              componentName: 'data-section',
              key: Math.random(),
              lighter: true,
              data: [{title: this.$gettext('Message for the interpreter'), data: this.jobDescription}]
            }, {
              condition: this.isShowProvider,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.$gettext('Booked on'), data: this.assignmentObj.provider}]
            }, {
              condition: this.userIsNotInterpreter && this.ccEmails.length,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.$gettext('CC emails'), data: this.ccEmails.join('<br>')}]
            }, {
              condition: this.userIsNotInterpreter && this.confirmationPhones.length,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.$gettext('Confirmation phones'), data: this.confirmationPhones.join('<br>')}]
            }, {
              condition: this.userIsInterpreter,
              componentName: 'cancellation-policy',
              key: Math.random()
            }]
          }]
        };
      },
      myInfoSectionData() {
        return {
          groups: [{
            components: [{
              condition: true,
              componentName: 'data-section',
              key: Math.random(),
              preview: this.userIsNotInterpreter ? this.getPreviewBooker() : null,
              data: [{title: this.$gettext('Booked by'), data: this.jobAuthor}]
            }, {
              condition: this.enterpriseDepartment,
              componentName: 'data-section',
              key: Math.random(),
              preview: this.getPreviewDepartment(),
              data: [{title: this.$gettext('Department'), data: this.enterpriseDepartmentName}]
            }, {
              condition: this.showAttendersSection,
              componentName: 'attenders-section',
              key: Math.random()
            }]
          }]
        };
      },
      invoiceSectionData() {
        return {
          groups: [{
            components: [{
              condition: this.clientName,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.$gettext('Client name'), data: this.clientName}]
            }, {
              condition: this.bookingReference && this.userIsNotInterpreter,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.bookingRefText, data: this.bookingReference}]
            }, {
              condition: this.paymentBookingReference && this.userIsNotInterpreter,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.paymentBookingRefText, data: this.paymentBookingReference}]
            }, {
              condition: this.caseNumber && this.userIsNotInterpreter,
              componentName: 'data-section',
              key: Math.random(),
              data: [{title: this.caseNumberText, data: this.caseNumber}]
            }, {
              condition: this.paymentMethod && this.userIsNotInterpreter,
              componentName: 'data-section',
              key: Math.random(),
              data: [{
                title: this.$gettext('Send invoice to'),
                data: this.paymentMethod
              }]
            }]
          }]
        };
      },

      // processed data for sections
      caseNumberText() { return this.$store.getters['UserInfoStore/labelForCaseNumber'] || this.$gettext('Case number'); },
      bookingRefText() { return this.$store.getters['UserInfoStore/labelForBookingRef'] || this.$gettext('Booking ref.'); },
      paymentBookingRefText() { return this.$store.getters['UserInfoStore/labelForPaymentBookingRef'] || this.$gettext('Attestant BID'); },
      publishedAtObj() {
        const {publishedAt, seriesOrderId, seriesSequenceNumber} = this.assignmentObj;
        const params = {
          publishedAt,
          id: this.$route.params.id,
          seriesOrderId,
          seriesSequenceNumber,
          category: this.category,
          subject: this.assignmentInfo.subject || ''
        };
        return this.getJobPublishedAtObj(params);
      },
      category() {
        const subCategory = this.assignmentInfo.jobCategoryItem || {};
        const category = subCategory.jobCategory || {};
        const categoryName = category.name || '';
        const subCategoryName = subCategory.name || '';
        return categoryName || subCategoryName ? `${categoryName} - ${subCategoryName}` : '';
      },
      subject() {
        return `${this.category} ${this.assignmentInfo.subject || ''}`.trim();
      },
      jobStartTimeObj() { return this.getJobStartTimeObj(this.assignmentRequirements.startTime); },
      jobFinishTimeObj() { return this.getJobFinishTimeObj(this.assignmentRequirements.finishTime); },
      jobVideoUrl() {
        return {
          title: this.$gettext('Video platform url'),
          urlData: this.assignmentRequirements.videoPlatformUrl
        };
      },
      jobDeadlineObj() { return this.assignmentRequirements.deadline && this.getJobDeadlineObj(this.assignmentRequirements.deadline); },
      manualBookingFeesObj() {
        const items = [];

        if (this.assignmentInfo.manualBookingFeeApplied) items.push(this.$gettext('Manual booking fee'));
        if (this.assignmentInfo.manualEditingFeeApplied) items.push(this.$gettext('Manual editing fee'));
        if (this.assignmentInfo.manualTravelBookingFeeApplied) items.push(this.$gettext('Travel booking fee'));
        return {
          title: this.$gettext('Manual extra charges'),
          data: items.join('<br />')
        };
      },
      qualificationText() {
        const qualifArr = this.$store.state.selectsData.qualification;
        for (let i = 0; i < qualifArr.length; i += 1) {
          if (+qualifArr[i].id === +this.assignmentRequirements.qualificationId) {
            return qualifArr[i].name;
          }
        }
        return '';
      },
      genderText() {
        const genderArr = [{id: '0', name: this.$gettext('Male')}, {id: '1', name: this.$gettext('Female')}];
        for (let i = 0; i < genderArr.length; i += 1) {
          if (+genderArr[i].id === +this.assignmentRequirements.sex) {
            return genderArr[i].name;
          }
        }
        return '';
      },
      contactAddress() {
        const {line1, line2, city, postcode, country, county} = this.assignmentContactAddress;
        return ''.concat(
          (line1 ? `${line1}, ` : ''),
          (line2 ? `${line2}, ` : ''),
          (postcode ? `${postcode}, ` : ''),
          (city ? `${city}, ` : ''),
          (county ? `${county}, ` : ''),
          country
        );
      },
      contactExtra() {
        const videoRoomInfo = this.$gettext('This assignment will be conducted in-person, but the person you will interpret for is located somewhere else. There is a private video room that you will need to use at this address, and the contact person named here will help you connect to the assignment.');
        return this.assignmentIsVideoroom ? videoRoomInfo : this.assignmentContactAddress.extra;
      },
      cancelNotFoundText() {
        const template = this.$gettext('If this assignment type cannot be confirmed, %{domain} should cancel this assignment');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      cancelDirectSaidNoText() {
        const template = this.$gettext('If this specific interpreter cannot be confirmed, %{domain} should cancel this assignment');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      cancelGenderNotFoundText() {
        const template = this.$gettext('If this specific gender cannot be confirmed, %{domain} should cancel this assignment');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      cancelQualificationNotFoundText() {
        const template = this.$gettext('If this specific qualification cannot be confirmed, %{domain} should cancel this assignment');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      updateGenderText() {
        const updateTime = this.$moment(this.autoUpdateSexAt).format('YYYY-MM-DD HH:mm');
        const template = this.$gettext('This assignment will change to allow all genders at %{updateTime}');
        return this.$gettextInterpolate(template, {updateTime});
      },
      updateSessionTypeText() {
        const updateTime = this.$moment(this.autoUpdateSessionTypeAt).format('DD-MM-YYYY HH:mm');
        const videoPlatformName = this.videoPlatform.name || this.$gettext('own video solution');
        let template;
        if (this.assignmentProcessRequirement.autoUpdateToSessionType === 'phone') {
          template = this.$gettext('This assignment will change to phone assignment at %{updateTime}');
        } else template = this.$gettext('This assignment will change to video assignment at %{updateTime}');
        return this.$gettextInterpolate(template, {videoPlatformName, updateTime});
      },
      changeSessionTypeBtnText() {
        const temp = this.$gettext('Change to %{sessionType} assignment');
        let autoUpdateToSessionType = '';
        switch (this.assignmentProcessRequirement.autoUpdateToSessionType) {
          case 'phone':
            autoUpdateToSessionType = this.$gettext('phone');
            break;
          case 'video':
            autoUpdateToSessionType = this.$gettext('video');
            break;
        }
        return this.$gettextInterpolate(temp, {sessionType: autoUpdateToSessionType});
      },
      assignmentStartTimePassed() {
        return this.$moment().diff(this.$moment(this.assignmentRequirements.startTime)) >= 0;
      },
      updateAlternativeLanguageTypeText() {
        const updateTime = this.$moment(this.autoUpdateAlternativeLanguageAt).format('YYYY-MM-DD HH:mm');
        const template = this.$gettext('Alternative language search will automatically begin at %{updateTime}');

        return this.$gettextInterpolate(template, {updateTime});
      },
      directAutoUpdateText() {
        const updateTime = this.$moment(this.autoUpdateDirectAt).format('YYYY-MM-DD HH:mm');
        if (this.$isGodmode()) {
          const template = this.$gettext('This assignment will be auto cancelled at %{updateTime}');
          return this.$gettextInterpolate(template, {updateTime});
        } else {
          const template = this.$gettext('This assignment will be cancelled at %{updateTime}');
          return this.$gettextInterpolate(template, {updateTime});
        }
      }
    },
    methods: {
      fetchSuitableInterpreters() {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$emit('fetchsuitableinterpreters');
        this.$store.dispatch('OneAssignmentStore/getJobById', this.jobId).then(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      },
      autoUpdateAfterStartTime(autoUpdateDate) {
        return autoUpdateDate
          ? this.$moment(autoUpdateDate).diff(this.$moment(this.assignmentRequirements.startTime)) >= 0
          : false;
      },
      getFilteredComponents(components) {
        return components.filter((component) => component.condition);
      },
      getFilteredGroups(groups) {
        return groups.filter((group) => {
          return group.components.some((component) => !!component.condition);
        });
      },
      getJobLink(jobId, className) {
        const routeName = this.userIsNotInterpreter ? 'BuyerOneAssignment' : 'ServerOneAssignment';
        const link = jobId && this.$router.resolve(this.$makeRoute({name: routeName, params: {id: jobId}})).href;
        return `<a class="sk-link sk-link--gray-bg ${className}" target="_blank" href="${link}">${jobId}</a>`;
      },
      getPreviewDepartment() {
        return helpers.getSelectedPreview({email: this.enterpriseDepartmentEmail, phone: this.enterpriseDepartmentPhone});
      },
      getPreviewBooker() {
        const selectedItem = this.assignmentParticipants.find((item) => item.role == 'booker') || {};
        selectedItem.phone = this.assignmentInfo.ownerPhone || '';
        return helpers.getSelectedPreview(selectedItem);
      },
      downgradeProcessRequirements(requirement) {
        this.$store.dispatch('OneAssignmentStore/downgradeProcessRequirements', {jobId: this.jobId, requirement})
          .then(() => {
            this.fetchSuitableInterpreters();
          });
      }
    }
  };
</script>

<style>
  .assignment-info__delimiter {
    margin-right: 5px;
  }

  .assignment-info__delimiter:not(:last-child)::after {
    content: ',';
  }

  .assignment-info__cancel-note {
    display: flex;
    align-items: center;
    min-height: 35px;
    margin-left: -5px;
    padding-left: 35px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIwMCIgaGVpZ2h0PSIxMjAwIiB2aWV3Qm94PSIwIDAgMTIwMCAxMjAwIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MDAgNjAwKSBzY2FsZSgwLjY5IDAuNjkpIHJvdGF0ZSgtMTgwKSB0cmFuc2xhdGUoLTYwMCAtNjAwKSIgc3R5bGU9ImZpbGw6IzAwMDAwMCI+PHN2ZyBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cGF0aCBkPSJNMTIsMmMtMS4wNzYsMS4wMjktMi4zNzEsMi4yNDQtMy42MDQsMy41NzlsMTEuMTA1LDExLjEwM0MxOS44MTgsMTUuODMyLDIwLDE0LjkyOSwyMCwxNEMyMCw5LjQyLDE1LjA5Miw0Ljk1NSwxMiwyeiI+PC9wYXRoPjxwYXRoIGQ9Ik00LDE0YzAsNC4yMjMsMy41ODIsOCw4LDhjMi4xMjEsMCw0LjA0NC0wLjg3Niw1LjQ3Ni0yLjI0OUwxOS43MjUsMjJMMjEsMjAuNzNMMy4yNzUsM0wyLDQuMjc1bDQuMTExLDQuMTExICBDNC44NzQsMTAuMTY4LDQsMTIuMDczLDQsMTR6Ij48L3BhdGg+PC9zdmc+PC9nPjwvc3ZnPg==);
    background-position: left center;
    background-size: 33px auto;
    background-repeat: no-repeat;
  }

  .sk-modal--invoice .payment-method .sk-select__list {
    top: -2px;
    max-height: 200px;
    transform: translateY(-100%);
  }
</style>

<style scoped>
.assignment-info {
  display: block;
  width: 100%;
}

.assignment-info__section {
  position: relative;
  margin-bottom: 10px;
  padding: 0 15px;
  background-color: #fff;
}

#app.salita-facelifted .assignment-info__section {
  border-radius: 8px;
  box-shadow: var(--shadow-and-blur);
}

.assignment-info__section--dark {
  border-radius: 10px;
  background-color: #aaa8be;
}

#app.salita-facelifted .assignment-info__section--dark {
  border-radius: 8px;
}

.assignment-info__section--direct,
.assignment-info__section--direct .assignment-info__section-title {
  background: #ff5b23;
  color: #fff;
}

.assignment-info__section-title {
  padding-top: 20px;
  color: #000;
  font-size: 18px;
  line-height: 14px;
}

.assignment-info__inner-sections {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #0003;
}

.assignment-info__edit-btn {
  position: absolute;
  top: 20px;
  right: 15px;
  z-index: 1;
}

.assignment-info__inner-sections .assignment-info__edit-btn {
  right: 0;
}

.assignment-info__inner-sections:last-child {
  border-bottom: none;
}

.assignment-info__inner-section {
  padding-bottom: 20px;
}

.assignment-info__inner-section:last-child {
  padding-bottom: 0;
}

.attachment__edit-btn {
  margin-top: 20px;
}
</style>
