(<template>
  <button class="sk-btn sk-btn--white system-log-btn"
          @click="openSystemLogModal">
    {{ $gettext('System log') }}
  </button>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
      openSystemLogModal() {
        const iframeSrc = this.$router.resolve(
          this.$makeRoute({name: 'SystemLog', params: {id: this.$route.params.id}, query: {page: 1, isOnTranslation: this.data.data?.isOnTranslation || false}})
        ).href;
        this.$store.commit('ModalStore/setModal', {
          component: 'system-log-modal',
          classes: ['sk-modal--new', 'system-log-modal'],
          width: 660,
          data: {
            title: this.$gettext('System log'),
            data: {
              iframeSrc
            }
          }
        });
      }
    }
  };
</script>
