(<template>
  <article class="chat-revoke">
    <div v-if="userIsInterpreter"
         class="chat-revoke__content">
      <p class="chat-revoke__text"
         v-html="interpreterRevokeText"></p>
    </div>
    <div v-else
         class="chat-revoke__content">
      <p class="chat-revoke__text"
         v-html="businessRevokeText"></p>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      },
      fullData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters({
        userIsInterpreter: 'UserInfoStore/userIsInterpreter'
      }),
      interpreterRevokeText() {
        return this.$gettext('This assignments requirements have changed. Please re-apply if you are available.');
      },
      businessRevokeText() {
        return this.$gettext('This assignments requirements have changed.');
      }
    }
  };
</script>

<style scoped>
  .chat-revoke__text {
    margin-bottom: 5px;
    color: #f04;
    color: var(--color-error);
    font-size: 14px;
  }
</style>
