(<template>
  <article class="chat-simple-msg">
    <template v-if="attachment">
      <div class="chat-simple-msg__file">
        <p class="chat-attachment__file-name">{{ filename }}</p>
        <button class="sk-link chat-attachment__download"
                @click="decriptFile(fileLink)">{{ downloadText }}</button>
      </div>
    </template>
    <template v-else>
      {{ eventData.data.text }}
    </template>
  </article>
</template>)

<script>
  export default {
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      downloadText() { return this.$gettext('Download this document'); },
      attachment() { return this.eventData.data.attachment || ''; },
      filename() {
        return this.attachment ? this.attachment.name : '';
      },
      fileLink() {
        return this.attachment ? this.attachment.file : '';
      }
    },
    methods: {
      decriptFile(link) {
        this.$store.dispatch('decriptFile', link).then((newLink) => {
          window.location.href = newLink;
        });
      }
    }
  };
</script>

<style scoped>
  .chat-simple-msg {
    word-break: break-all;
  }

  .chat-attachment__download {
    margin-bottom: 5px;
    font-weight: normal;
  }

  .chat-attachment__download::after {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 16px;
    height: 16px;
    margin: 2px 0 2px 5px;
    background-image: url(~@assets/imgs/undefined_imgs/download-ico.svg);
    background-position: 50% 50%;
    background-size: 16px auto;
    background-repeat: no-repeat;
  }

  @media (max-width: 500px) {
    .chat-simple-msg__file {
      margin: 0 -10px;
    }
  }
</style>
