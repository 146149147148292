(<template>
  <article class="chat-overtime">
    <div class="chat-overtime__content">
      <p class="chat-overtime__text">{{ overtimeText }}</p>
      <template v-if="userIsInterpreter && eventData.own">
        <p v-for="text in additionText"
           :key="text"
           class="chat-overtime__text"
           v-html="text"></p>
      </template>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      overtime() {
        return this.eventData.data.overtime || 0;
      },
      overtimeText() {
        const template = this.$gettext('Overtime: %{m} minutes');
        return this.$gettextInterpolate(template, {m: this.overtime});
      },
      additionText() {
        return [
          this.$gettext('It may take up to 72 hours after you submit your feedback to get a confirmation.'),
          this.$gettext('Our customers have the right to dispute your feedback, demand receipts, and other explanation for all costs incurred.'),
          this.$gettext('We will coordinate your feedback with the customer and will ensure that the assignment displays the correct amounts before the 3rd of each month for the previous month.'),
          this.$gettextInterpolate('You can read more about our invoicing guidelines here: <a class="sk-link" href="%{link}" target="_blank">Billing Guidelines</a>', {
            link: this.$getString('domainData', 'financeGuideLink')
          }),
        ];
      }
    }
  };
</script>

<style scoped>
  .chat-overtime__text {
    margin-bottom: 5px;
    color: #f93;
    font-size: 14px;
  }
</style>
