(<template>
  <div class="direct-invite">
    <p class="direct-invite__note">
      <strong class="direct-invite__important">{{ warningNoticeText }}</strong>
      <span>{{ noteText }}</span>
    </p>
  </div>
</template>)

<script>
  export default {
    computed: {
      noteText() {
        return this.$gettext('please confirm or decline if you can take this assignment. You are the only one invited for this assignment.');
      },
      warningNoticeText() {
        return this.$gettext('Important:');
      }
    }
  };
</script>

<style scoped>
  .direct-invite {
    margin-right: -15px;
    margin-left: -15px;
    padding: 0 15px 15px;
    background: inherit;
    color: inherit;
  }

  .direct-invite__important {
    text-decoration: underline;
  }
</style>
