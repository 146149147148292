(<template>
  <sk-note :text="interPolicyInfoText" />
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        paymentInfoLink: this.$getString('domainData', 'financeGuideLink')
      };
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        assignmentRequirements: (state) => state.storeJobObj.interpretationRequirement || {}
      }),
      assignmentType() { return this.assignmentRequirements.sessionType; },
      interPolicyJobTypeText() {
        const template = this.$gettext('You do not have the opportunity to withdraw from a confirmed assignment later than 48 hours before the assignment starts.</br></br> An assignment assigned to you must be performed by you personally and never transferred to another interpreter without the approval of Salita.</br></br> %{text}</br></br> It is your responsibility to ensure access to the necessary work tools, sufficient battery capacity etc. to be able to carry out the interpretation.');
        let text = '';
        switch (this.assignmentType) {
          case 'video':
            text = this.$gettext('For video assignments, you commit to be ready at a suitable place, at the agreed time, and be prepared and available to the customer throughout the agreed time. It is your responsibility to have access to the necessary working tools, sufficient battery capacity, etc. to conduct the interpretation.');
            break;
          case 'phone':
            text = this.$gettext('For telephone and screen assignments, you undertake to be ready at a suitable place at the agreed time, and to be available to the customer throughout the ordered time.');
            break;
          case 'in_person':
            text = this.$gettext('For In-person assignments, you commit to travel to the agreed location, and be prepared in advance of the assignment start.');
            break;
          case 'videoroom':
            text = this.$gettext('For videoroom assignments, you commit to travel to the agreed location, and be prepared in advance of the assignment start.');
            break;
        }
        return text ? this.$gettextInterpolate(template, {text}) : '';
      },
      interPolicyInfoText() { return `${this.interPolicyJobTypeText}<br><br>`; }
    }
  };
</script>
