(<template>
  <button class="sk-btn sk-btn--white system-log-btn lock-job-btn"
          @click="lockJob">
    {{ $gettext('Lock Job') }}
  </button>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
      lockJob() {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('OneAssignmentStore/lockJob', this.$route.params.id).then(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      }
    }
  };
</script>
<style scoped>
.lock-job-btn {
  margin-bottom: 10px;
}
</style>
