(<template>
  <article class="chat-apply">
    <div class="chat-apply__content">
      <p class="chat-apply__offer-text">{{ computedOfferText }}</p>
      <p v-if="chatWithFeedback">{{ chatWithFeedbackText }}</p>
      <template v-else>
        <template v-if="userCanSeePrices || $isGodmode()">
          <!-- PRICE TEMPLATES JOBS -->
          <invoice-draft-info v-if="showPriceTemplatePrices"
                              :hide-tips="true"
                              :hide-groups="true"
                              :invoice="offerData"
                              class="chat-apply__draft-invoice" />
        </template>
        <!-- ACTIONS -->
        <template v-if="userIsInterpreter">
          <button :disabled="disableWithdraw"
                  class="sk-btn sk-btn--like-link chat-apply__destroy-btn"
                  @click="catchDecline">{{ $gettext('Withdraw offer') }}</button>
        </template>
        <template v-else>
          <button :disabled="!activeActionBtn"
                  class="sk-btn sk-btn--default chat-apply__award-btn"
                  @click="priceTemplateJobAward">{{ $gettext('Accept offer') }}</button>
          <button :disabled="btnShouldBeDisabled"
                  class="sk-btn sk-btn--like-link chat-apply__destroy-btn"
                  @click="catchReject">{{ rejectInterpreterText }}</button>
        </template>
      </template>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import WSManager from '@/modules/ws_data_module';
  import InvoiceDraftInfo
    from '@/components/assignment_components/one_assignment/interactive_info/shared_components/InvoiceDraftInfo';
  import JobActionsPrototype from '@/prototypes/JobActionsPrototype';

  export default {
    components: {
      'invoice-draft-info': InvoiceDraftInfo
    },
    extends: JobActionsPrototype,
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      },
      fullData: {
        type: Object,
        default() {
          return {};
        }
      },
      fullJobData: {
        type: Object,
        default() {
          return {};
        }
      },
      pastEvent: Boolean
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCanManageJob',
        'userIsEnterpriseMember',
        'userCanSeePrices',
        'userIsBusiness'
      ]),
      jobLocked() {
        return this.fullJobData?.locked;
      },
      isLastApplyEvent() {
        return this.eventData.lastApplyEvent;
      },
      showPriceTemplatePrices() {
        const jobIsNotLocked = !this.jobLocked;
        const showPricesForInterpreter = this.userIsInterpreter && this.isLastApplyEvent && jobIsNotLocked;
        return showPricesForInterpreter || this.$isGodmode();
      },
      businessOfferText() {
        const template = this.isAutoApproveEnabled ? this.$gettext('%{n} is interested in this assignment') : this.$gettext('%{n} sent you an offer');
        return this.$gettextInterpolate(template, {n: this.supplierInfo.name || ''});
      },
      computedOfferText() {
        return this.userIsInterpreter ? this.$gettext('You sent an offer') : this.businessOfferText;
      },
      activeActionBtn() {
        return !this.pastEvent && this.userCanManageJob && !this.btnShouldBeDisabled;
      },
      supplierInfo() {
        return this.fullData && this.fullData.supplierInfo ? this.fullData.supplierInfo : {};
      },
      chartEvents() { return this.fullData?.events || []; },
      chatWithFeedback() {
        return this.chartEvents.some((event) => event.type === 'feedback');
      },
      chatWithFeedbackText() { return this.$gettext('Amounts have been changed based on your feedback. See below.'); },
      rejectInterpreterText() {
        return this.currentBusinessDiscussionStatus == 'accepted'
          ? this.$gettext('Cancel assignment')
          : this.$gettext('Decline offer');
      },
      disableWithdraw() {
        return this.interDiscussion.status === 'rejected'
          || this.interDiscussion.status === 'declined'
          || this.interDiscussion.status === 'withdrawn'
          || this.assignmentObj.status === 'cancelled'
          || this.assignmentObj.status === 'finished'
          || this.assignmentObj.status === 'in_progress'
          || this.assignmentObj.status === 'needsFeedback'
          || this.jobLocked
          || !this.isLastApplyEvent
          || this.isShortNoticeWithdraw;
      },
      btnShouldBeDisabled() {
        return this.interDiscussion.status === 'rejected'
          || this.interDiscussion.status === 'declined'
          || this.interDiscussion.status === 'withdrawn'
          || this.assignmentObj.status === 'cancelled'
          || this.jobLocked
          || !this.isLastApplyEvent;
      }
    },
    methods: {
      // BUYER AWARD
      priceTemplateJobAward() {
        this.$store.commit('AssignmentFlowStore/clearFlowData');
        this.$store.commit('AssignmentFlowStore/setPricingModalData', {invoice: this.offerData});
        this.$store.commit('ModalStore/setModal', {
          component: 'award-assign',
          data: {
            title: this.$gettext('Accept offer'),
            context: this
          }
        });
      },
      // PAYMENTS FOR AWARD
      openAddPaymentModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'payment-method-option',
          width: 510,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Payment Methods'),
            context: this,
            successHandlingCallback: 'makeAward'
          }
        });
      },
      openForceApplyConfirmationModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Confirm'),
            text: this.$gettext('This interpreter is unsuitable due to availability. Are you sure you want to confirm this interpreter for this assignment?'),
            context: this,
            modalCallback: this.makeAward,
            callbackParams: {
              force: true
            },
            btnTexts: {
              actionBtnText: this.$gettext('Confirm interpreter'),
              cancelBtnText: this.$gettext('Cancel')
            }
          }
        });
      },
      // AWARD
      makeAward({force} = {force: false}) {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('OneAssignmentStore/makeAward', {
          discussionId: this.chosenDiscussionID,
          force
        }).then((data) => {
          WSManager.setDataFromSockets(this, data);
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch((error) => {
          const errors = error?.data?.errors;
          if (errors?.payment_method_present?.includes('Default payment method is absent')) {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('Please set a default payment method first'),
              method: 'goToPaymentMethodsPage',
              context: this
            });
            return;
          }
          if (error?.data?.awardable === 'No payment methods') {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
            setTimeout(() => {
              this.$scrollToErrors();
              this.$store.commit('ModalStore/setModalErrors', this.$gettext('Please add a payment method first'));
              if (this.userIsBusiness) {
                this.$store.commit('ModalStore/setModal', {
                  component: 'error-modal',
                  data: {
                    error
                  }
                });
              } else {
                this.openAddPaymentModal();
              }
            }, 0);
            return;
          }
          if (error?.status == 401) {
            this.$store.dispatch('UserInfoStore/fetchUserInfo', this.$store.getters['UserInfoStore/userUid']).then(() => {
              this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
              this.$store.dispatch('ModalStore/closeModal');
              setTimeout(() => {
                this.$store.commit('InfoModalStore/setInfoModal', {
                  text: this.$gettext('Your rights in the Enterprise have been changed. Please try again.')
                });
              }, 0);
            });
            return;
          }
          if (errors?.suitable_interpreter?.includes('Interpreter is not suitable anymore')) {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
            this.$store.dispatch('ModalStore/closeModal');
            if (this.$isGodmode()) {
              setTimeout(() => {
                this.openForceApplyConfirmationModal();
              });
            } else {
              setTimeout(() => {
                this.$store.commit('InfoModalStore/setInfoModal', {
                  text: this.$gettext('This interpreter is no longer available. Please select another interpreter.'),
                  method: 'reloadPage',
                  context: this
                });
              });
            }
            return;
          }
          if (errors?.correct_job_status?.includes('Wrong job status')) {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Suitable interpreter is already assigned to your session. If you want new interpreter please reject previous one.'),
                method: 'reloadPage',
                context: this
              });
            });
            return;
          }
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          });
        });
      },
      reloadPage() {
        window.location.reload();
      },
      goToPaymentMethodsPage() {
        this.$router.push(this.$makeRoute({
          name: this.userIsEnterpriseMember
            ? 'BuyerCompanyPayments'
            : 'BuyerPayments'
        }));
      },
    }
  };
</script>

<style>
  .chat-apply__travel-row .name {
    display: inline-block;
    width: 140px;
  }
</style>

<style scoped>
  .chat-apply {
    text-align: center;
  }

  .chat-apply__offer-text {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .chat-apply__draft-invoice {
    margin: 10px 0;
  }

  .chat-apply__award-btn {
    height: 30px;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 30px;
  }

  .chat-apply__destroy-btn {
    color: #f04;
    color: var(--color-error);
    font-size: 12px;
    text-decoration: underline;
  }

  .chat-apply__destroy-btn[disabled],
  .chat-apply__destroy-btn[disabled]:hover,
  .chat-apply__destroy-btn[disabled]:focus,
  .chat-apply__destroy-btn[disabled]:active {
    color: #8b8d8f;
    text-decoration: none;
    cursor: not-allowed;
  }
</style>
