(<template>
  <button class="edit-pencil-btn"
          @click="edit"></button>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import DangerousAssignmentEditMixin from '@/mixins/DangerousAssignmentEditMixin';

  export default {
    mixins: [DangerousAssignmentEditMixin],
    props: {
      sectionName: {
        type: String,
        default: ''
      },
      validateFunc: {
        type: String,
        default: ''
      },
      fetchFunc: {
        type: String,
        default: ''
      },
      modalClasses: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapGetters('OneAssignmentStore', [
        'storeJob',
        'isSecretaryAssignment'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {},
      }),
      jobId() { return this.$route.params.id; },
      jobStatus() { return this.assignmentObj.status || ''; },
      isPublishedJob() { return this.jobStatus === 'published'; },
    },
    methods: {
      edit() {
        this.$store.commit('ErrorsStore/removeAllErrors');
        this.$store.dispatch('PostInterpretationStore/clearStore');
        this.$store.commit('PostInterpretationStore/setJobId', this.jobId);
        this.$store.dispatch('PostingNotifierStore/preselectInterpretationEditStaticData');
        this.$store.commit('PostInterpretationStore/setJobInfo', {
          info: this.storeJob,
          rootGetters: this.$store.getters,
          rootState: this.$store.state
        });
        if (this.fetchFunc) {
          this.$store.dispatch(this.fetchFunc)
            .then(() => {
              this.openEditModal();
            });
        } else {
          this.openEditModal();
        }
      },
      openEditModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'assignment-info-edit-modal',
          classes: ['sk-modal--new', ...this.modalClasses],
          data: {
            sectionName: this.sectionName,
            validateFuncName: this.validateFunc,
            successHandlingFunc: this.successHandlingFunc,
            sendInfoFunc: this.sendInfoFunc,
            submitButtonText: this.$gettext('Confirm changes')
          }
        });
      },
      editJob() {
        this.$store.commit('ModalStore/startModalProgress');
        this.$store.commit('PostInterpretationStore/setTrackType', 'job_updated');

        return this.isSecretaryAssignment
          ? this.$store.dispatch('PostSecretaryStore/editJob')
          : this.$store.dispatch('PostInterpretationStore/editJob');
      },
      sendInfoFunc() {
        // check if dangerous edit
        if (!this.checkDangerousFieldEdit()) {
          return this.editJob();
        } else {
          return new Promise(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'dangerous-edit',
              width: 510,
              data: {
                context: this,
                data: {
                  description: this.$gettext('Changes will cancel your current assignment. We will automatically redirect you to the page where you can create a new assignment and rebook it.'),
                  text: this.$gettext('I confirm that these changes are necessary, and need to cancel and rebook with new information.')
                }
              }
            });
          });
        }
      },
      successHandlingFunc() {
        this.$store.dispatch('ModalStore/closeModal');
        this.$store.commit('ModalStore/stopModalProgress');
        this.$store.dispatch('OneAssignmentStore/getJobById', this.jobId).then(() => {
          if (this.isPublishedJob) {
            this.$store.commit('FAIListStore/clearSuitableInterpreters');
            this.$emit('fetchsuitableinterpreters');
          }
        });
      },
    }
  };
</script>
