(<template>
  <div class="pricing-templates">
    <h2 class="pricing-templates__title">{{ descriptionText }}</h2>
    <pricing-template-selectors :disable-selectors="jobLocked"
                                class="pricing-templates__selectors">
      <button slot="demanderInternal"
              :disabled="disableDemanderInternalTemplate"
              class="sk-btn sk-btn--white pricing-templates__btn"
              @click="updateDemanderInternalTemplate">{{ updateText }}</button>
      <button slot="demanderExternal"
              :disabled="disableDemanderExternalTemplate"
              class="sk-btn sk-btn--white pricing-templates__btn"
              @click="updateDemanderExternalTemplate">{{ updateText }}</button>
      <button slot="supplierHonorar"
              :disabled="disableSupplierHonorarTemplate"
              class="sk-btn sk-btn--white pricing-templates__btn"
              @click="updateSupplierHonorarTemplate">{{ updateText }}</button>
      <button slot="supplierCompany"
              :disabled="disableSupplierCompanyTemplate"
              class="sk-btn sk-btn--white pricing-templates__btn"
              @click="updateSupplierCompanyTemplate">{{ updateText }}</button>
      <button slot="supplierEmployee"
              :disabled="disableSupplierEmployeeTemplate"
              class="sk-btn sk-btn--white pricing-templates__btn"
              @click="updateSupplierEmployeeTemplate">{{ updateText }}</button>
    </pricing-template-selectors>
  </div>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';
  import PricingTemplateSelectors from '@/components/shared_components/PricingTemplateSelectors';

  export default {
    components: {
      'pricing-template-selectors': PricingTemplateSelectors
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        storeJobObj: ({storeJobObj}) => storeJobObj || {}
      }),
      ...mapState('PricingTemplatesStore', {
        demanderInternalTemplate: ({demanderInternalTemplate}) => demanderInternalTemplate,
        demanderExternalTemplate: ({demanderExternalTemplate}) => demanderExternalTemplate,
        supplierHonorarTemplate: ({supplierHonorarTemplate}) => supplierHonorarTemplate,
        supplierCompanyTemplate: ({supplierCompanyTemplate}) => supplierCompanyTemplate,
        supplierEmployeeTemplate: ({supplierEmployeeTemplate}) => supplierEmployeeTemplate,
      }),
      jobLocked() { return this.storeJobObj.locked; },
      updateText() { return this.$gettext('Update'); },
      descriptionText() { return this.$gettext('Pricing templates'); },
      disableDemanderInternalTemplate() { return !this.demanderInternalTemplate || this.jobLocked; },
      disableDemanderExternalTemplate() { return !this.demanderExternalTemplate || this.jobLocked; },
      disableSupplierHonorarTemplate() { return !this.supplierHonorarTemplate || this.jobLocked; },
      disableSupplierCompanyTemplate() { return !this.supplierCompanyTemplate || this.jobLocked; },
      disableSupplierEmployeeTemplate() { return !this.supplierEmployeeTemplate || this.jobLocked; }
    },
    methods: {
      ...mapMutations('PricingTemplatesStore', ['setTemplate']),
      updateDemanderInternalTemplate() { this.updateTemplate('demander_internal_pricing_template_id', this.demanderInternalTemplate); },
      updateDemanderExternalTemplate() { this.updateTemplate('demander_external_pricing_template_id', this.demanderExternalTemplate); },
      updateSupplierHonorarTemplate() { this.updateTemplate('supplier_honorar_pricing_template_id', this.supplierHonorarTemplate); },
      updateSupplierCompanyTemplate() { this.updateTemplate('supplier_company_pricing_template_id', this.supplierCompanyTemplate); },
      updateSupplierEmployeeTemplate() { this.updateTemplate('supplier_employee_pricing_template_id', this.supplierEmployeeTemplate); },
      updateTemplate(type, id) {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('PricingTemplatesStore/updateCurrentTemplate', {type, id, jobId: this.storeJobObj.id}).then(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          this.$store.commit('InfoModalStore/setInfoModal', {
            text: this.$gettext('Sorry, you can\'t change this pricing template anymore.')
          });
        });
      }
    }
  };
</script>

<style scoped>
.pricing-templates__title {
  display: block;
  margin-bottom: -10px;
  color: #5a5964;
  font-weight: normal;
  font-size: 10px;
}

.pricing-templates__btn {
  margin: 10px 10px 0;
}
</style>
