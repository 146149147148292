(<template>
  <li v-if="showInterTile"
      :class="{'inter-for-list--chosen': currentDiscussionClass,
               'inter-for-list__status-text--withdrawn': msgWithdrawnClass,
               'inter-for-list__last-awarded': lastAwardedClass,
               'inter-for-list--finished-assignment': isFinishedAssignment}"
      class="inter-for-list"
      @click="setInterpreter">
    <router-link :to="interpreterLink"
                 target="_blank">
      <inter-avatar :interpreter="interpreterInfo"
                    :is-chat-list="true" />
    </router-link>
    <div class="inter-for-list__info-wrapper">
      <div class="inter-for-list__first-row">
        <p class="inter-for-list__inter-name">{{ interName }}</p>
        <p class="inter-for-list__msg-date">{{ lastEventsDate }}</p>
      </div>
      <div class="inter-for-list__second-row">
        <p class="inter-for-list__inter-level">{{ interQualification }}</p>
      </div>
      <div v-if="msgText"
           class="inter-for-list__second-row">
        <p class="inter-for-list__msg-text">{{ msgText }}</p>
      </div>
      <div class="inter-for-list__second-row">
        <p v-if="statusText"
           class="inter-for-list__status-text"
           :class="{'inter-for-list__status-text--red': msgRedClass,
                    'inter-for-list__status-text--green': msgGreenClass,
                    'inter-for-list__status-text--yellow': msgYellowClass,
                    'inter-for-list__status-text--decline': msgDeclineClass}">{{ statusText }}</p>
        <p v-if="closedStatus"
           class="inter-for-list__status-text--closed">{{ $gettext('Closed') }}</p>
      </div>
      <div v-if="$isGodmode()"
           class="inter-for-list__second-row">
        <span v-if="interCity"
              class="inter-for-list__city">{{ interCity }}</span>
        <a v-if="interPhone"
           :href="`tel:${interPhone}`"
           class="js-copy-to-clipboard">{{ interPhone }}</a>
      </div>
      <div v-if="$isGodmode() && interDistance"
           class="inter-for-list__second-row">
        <span class="inter-for-list__distance">{{ interDistanceText }}</span>
      </div>
    </div>
  </li>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';
  import Interpreter from '@/components/interpreters_components/InterForDashboardList';

  export default {
    components: {
      'inter-avatar': Interpreter
    },
    props: {
      discussion: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      ...mapState('OneAssignmentStore', {
        currentDiscussionId: (state) => state.currentDiscussionId,
        lastAwardedInterpreterId: (state) => state.storeJobObj.lastAwardedInterpreterUid,
        assignmentStatus: (state) => state.storeJobObj.status
      }),
      clientWidth() {
        return this.$store.state.GlobalStore.clientWidth;
      },
      showInterTile() {
        return this.clientWidth;
      },
      interData() {
        return this.discussion.supplierInfo || {};
      },
      interPhone() {
        return this.interData.phone;
      },
      interCity() {
        return this.interData.city;
      },
      interDistance() {
        return this.interData.distance;
      },
      discussionId() {
        return this.discussion.id;
      },
      keyEvent() {
        return this.discussion.keyEvent;
      },
      discussionStatus() {
        return this.discussion.status;
      },
      offer() {
        return this.discussion.offer;
      },
      interName() {
        const name = this.interData.name ? this.interData.name.split(' ')[0] : '';
        return this.isExternal ? `[External] ${name}` : name;
      },
      interpreterLink() {
        return this.interData.uid ? this.$makeRoute({name: 'BuyerUserProfile', params: {id: this.interData.uid}}) : '';
      },
      qualificationsList() {
        return this.$store.state.selectsData.qualification || [];
      },
      isExternal() {
        return this.interData.external;
      },
      isFinishedAssignment() {
        return this.assignmentStatus === 'finished';
      },
      interQualification() {
        const qualificationShortName = helpers.getJobInfo.getQualificationName(this, {
          id: this.interData.qualification,
          showShortName: true
        }) || '';
        const languageName = this.$getLangName(this.interData.languageToId) || '';

        return `${languageName} - ${qualificationShortName}`;
      },
      lastEventsDate() {
        if (this.keyEvent) {
          const eventMoment = this.$moment(this.keyEvent.date);
          const nowMoment = this.$moment();
          if (nowMoment.year() == eventMoment.year() && nowMoment.month() == eventMoment.month() && nowMoment.date() == eventMoment.date()) {
            return eventMoment.format('HH:mm');
          } else if (nowMoment.week() == eventMoment.week()) {
            return eventMoment.format('ddd');
          } else {
            return eventMoment.format('DD MMM HH:mm');
          }
        }
      },
      msgText() {
        return this.keyEvent && this.keyEvent.data ? this.keyEvent.data.text : '';
      },
      statusText() {
        switch (this.discussionStatus) {
          case 'withdrawn':
            return this.$gettext('Withdrawn');
          case 'declined':
          case 'abandoned':
            return this.$gettext('Declined');
          case 'rejected':
            return this.$gettext('Rejected');
          case 'applied':
            return this.$gettext('Offer');
          case 'invited':
            return this.$gettext('Invited');
          case 'accepted':
            return this.$gettext('Confirmed');
          default:
            return '';
        }
      },
      msgRedClass() {
        return ['withdrawn', 'rejected', 'abandoned'].includes(this.discussionStatus);
      },
      msgGreenClass() {
        return this.discussionStatus === 'accepted';
      },
      msgYellowClass() {
        return this.discussionStatus === 'applied';
      },
      msgDeclineClass() {
        return this.discussionStatus === 'declined';
      },
      msgWithdrawnClass() {
        return this.discussionStatus === 'withdrawn';
      },
      currentDiscussionClass() {
        return +this.currentDiscussionId === +this.discussionId;
      },
      lastAwardedClass() {
        const uidFromProps = this.discussion.supplierInfo.uid;
        return uidFromProps === this.lastAwardedInterpreterId && this.discussionStatus === 'cancelled';
      },
      interpreterInfo() {
        return {
          avatar: this.interData.avatar,
          online: this.interData.online,
          favourite: this.interData.favourite,
          uid: this.interData.uid
        };
      },
      closedStatus() {
        return this.discussion && this.discussion.status === 'rejected';
      },
      interDistanceText() {
        const distance = helpers.getJobInfo.distanceText(this.interDistance, this);
        return this.$gettextInterpolate(this.$gettext('Distance: %{distance}'), {distance: distance});
      }
    },
    methods: {
      setInterpreter() {
        const isWideScreen = this.clientWidth > 1024;
        const isSameDiscussion = +this.currentDiscussionId === +this.discussionId;

        if ((isWideScreen && isSameDiscussion) || this.isFinishedAssignment) return;

        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('OneAssignmentStore/getOneDiscussion', this.discussionId).then(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          if (this.userIsInterpreter && this.clientWidth < 768) {
            this.$store.commit('OneAssignmentStore/openMobileChat');
          } else if (!this.userIsInterpreter && this.clientWidth < 1025) {
            this.$store.commit('OneAssignmentStore/openMobileChat');
          }
        });
      }
    }
  };
</script>

<style scoped>
.inter-for-list {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 30px;
  border-bottom: 1px solid #e9ebef;
  cursor: pointer;
}

.inter-for-list--chosen,
.inter-for-list:hover,
.inter-for-list:active,
.inter-for-list:focus {
  background-color: #f4f5f7;
}

.inter-for-list__info-wrapper {
  display: block;
  flex-grow: 1;
  width: calc(100% - 45px);
}

.inter-for-list__first-row,
.inter-for-list__second-row {
  display: flex;
  width: 100%;
  padding-left: 10px;
  font-size: 14px;
}

.inter-for-list__second-row,
.inter-for-list__msg-date {
  font-size: 12px;
}

.inter-for-list__city,
.inter-for-list__inter-name,
.inter-for-list__distance {
  overflow: hidden;
  max-width: 100%;
  margin-right: auto;
  padding-right: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inter-for-list__inter-level {
  color: #333;
  opacity: 0.5;
}

.inter-for-list__msg-date,
.inter-for-list__status-text--closed {
  flex-shrink: 0;
  margin-left: auto;
}

.inter-for-list__status-text--closed {
  color: rgba(51, 51, 51, 0.5);
}

.inter-for-list__msg-text,
.inter-for-list__status-text {
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inter-for-list__msg-text {
  opacity: 0.5;
}

.inter-for-list__status-text--red {
  color: #646d8c;
  color: var(--color-primary);
}

.inter-for-list__status-text--green {
  color: #ff5b24;
  color: var(--color-secondary);
}

.inter-for-list__status-text--yellow {
  color: #f93;
}

.inter-for-list__status-text--decline {
  color: #3f43ad;
}

.inter-for-list__status-text--withdrawn {
  background-color: #ffc0cb;
}

.inter-for-list__last-awarded {
  background-color: #fbefea;
}

.inter-for-list--finished-assignment {
  cursor: not-allowed;
}
</style>
