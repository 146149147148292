(<template>
  <article class="chat-cancel">
    <div class="chat-cancel__content">
      <p class="chat-cancel__text"
         v-html="cancelText"></p>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      },
      fullData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters({
        userIsInterpreter: 'UserInfoStore/userIsInterpreter'
      }),
      cancelText() {
        if (!this.eventData.own) {
          const template = this.$gettext('%{n} canceled the assignment.');
          return this.$gettextInterpolate(template, {n: this.fullData && this.fullData.demanderInfo ? this.fullData.demanderInfo.name : ''});
        } else {
          return this.$gettext('You have canceled this assignment.');
        }
      }
    }
  };
</script>

<style scoped>
  .chat-cancel__text {
    color: #3f43ad;
    font-size: 14px;
  }
</style>
