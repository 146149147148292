(<template>
  <div class="data-section">
    <section class="data-section__wrapper">
      <h2 class="data-section__title">{{ $gettext('Language') }}</h2>
      <div class="data-section__data">
        <div class="data-section__data-item">
          <span>{{ langFromObj.name }}</span>
        </div>
        <div class="data-section__data-item">
          <sk-tooltip v-if="showTolkeregisterTooltip"
                      :item-text="isInTolkeregisterText"
                      :position="'bottom-right'"
                      class="data-section__tooltip" />
          <span>{{ langToObj.name }}</span>
        </div>
      </div>
    </section>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsNotInterpreter']),
      languagesList() { return this.$store.state.selectsData.language || []; },
      langFromId() { return this.data.data ? this.data.data.langFromId : ''; },
      langToId() { return this.data.data ? this.data.data.langToId : ''; },
      langFromObj() { return this.getLangObj(this.langFromId); },
      langToObj() { return this.getLangObj(this.langToId); },
      showTolkeregisterTooltip() {
        return !(this.langFromObj.id == 3 && this.langToObj.is_in_tolkeregister) && this.userIsNotInterpreter;
      },
      isInTolkeregisterText() { return this.$gettext('The language is not listed in the National Interpreter Register'); }
    },
    methods: {
      getLangObj(langId) {
        return this.languagesList.find((lang) => lang.id == langId) || {};
      }
    }
  };
</script>

<style src="@assets/css/data_section.css" scoped></style>
