(<template>
  <article class="chat-decline">
    <div v-if="userIsInterpreter"
         class="chat-decline__content">
      <p class="chat-decline__text"
         v-html="interpreterDeclineText"></p>
    </div>
    <div v-else
         class="chat-decline__content">
      <p class="chat-decline__text">{{ businessDeclineText }}</p>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      },
      fullData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters({
        userIsInterpreter: 'UserInfoStore/userIsInterpreter'
      }),
      businessDeclineText() {
        let template;
        if (this.eventData.own) {
          template = this.$gettext('You have declined %{n}\'s offer.');
        } else {
          template = this.$gettext('%{n}\'s offer is withdrawn.');
        }
        return this.$gettextInterpolate(template, {n: this.fullData && this.fullData.supplierInfo ? this.fullData.supplierInfo.name : ''});
      },
      interpreterDeclineText() {
        if (this.eventData.own) {
          return this.$gettext('You have withdrawn from this assignment.');
        } else {
          return this.$gettext('Your offer has been declined. If you would like, you can apply again.');
        }
      }
    }
  };
</script>

<style scoped>
  .chat-decline__text {
    margin-bottom: 5px;
    color: #3f43ad;
    font-size: 14px;
  }
</style>
