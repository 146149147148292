(<template>
  <article class="chat-award">
    <div v-if="userIsInterpreter"
         class="chat-award__content">
      <div class="chat-award__icon"></div>
      <p class="chat-award__text">{{ interpreterAwardText }}</p>
      <p v-if="showTravelCostNote"
         class="chat-award__note"
         v-html="travelCostNoteText"></p>
    </div>
    <div v-else
         class="chat-award__content">
      <div class="chat-award__icon"></div>
      <p class="chat-award__text">{{ businessAwardText }}</p>
    </div>
  </article>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';

  export default {
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      },
      fullData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter'
      ]),
      ...mapState('OneAssignmentStore', {
        isAutoApproveEnabled: (state) => {
          return state.storeJobObj && state.storeJobObj.priceRequirement ? state.storeJobObj.priceRequirement.autoApprove : '';
        }
      }),
      ...mapGetters('AssignmentFlowStore', ['travelCostsPricing']),
      ...mapState('OneAssignmentStore', {
        assignmentObj: (state) => state.storeJobObj || {}
      }),
      showTravelCostNote() {
        return !!this.ptTravelCosts;
      },
      ptTravelCosts() { return this.travelCostsPricing.travelExpenses || 0; },
      travelCostNoteText() {
        return this.$gettext('Unreasonable ticket prices will not be refunded. We retain the right to ask for receipts as proof. For rules regarding cost of diet please <a class="sk-link sk-link--gray-bg" href="https://arbeidsgiver.difi.no/lonn-goder-og-reise/reise/statens-satser-innenlands">click here.</a>');
      },
      businessAwardText() {
        const template = this.isAutoApproveEnabled ? this.$gettext('%{n} has been booked for your assignment.') : this.$gettext('%{n}\'s offer has been accepted.');
        return this.$gettextInterpolate(template, {n: this.fullData && this.fullData.supplierInfo ? this.fullData.supplierInfo.name : ''});
      },
      interpreterAwardText() {
        const template = this.$gettext('%{n} accepted your offer.');
        return this.$gettextInterpolate(template, {n: this.fullData && this.fullData.demanderInfo ? this.fullData.demanderInfo.name : ''});
      }
    },
    methods: {
      catchAward() {
        this.$emit('award');
      }
    }
  };
</script>

<style scoped>
  .chat-award__content {
    position: relative;
    padding-left: 15px;
  }

  .chat-award__icon {
    position: absolute;
    top: 50%;
    left: -15px;
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(~@assets/imgs/undefined_imgs/booked_icon.svg);
    background-position: 50% 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }

  .chat-award__text {
    margin-bottom: 5px;
    color: #ff5b24;
    color: var(--color-secondary);
    font-size: 14px;
  }

  .chat-award__note {
    font-size: 12px;
  }
</style>
