// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/booked_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.chat-award__content[data-v-4b229b53] {\n  position: relative;\n  padding-left: 15px;\n}\n.chat-award__icon[data-v-4b229b53] {\n  position: absolute;\n  top: 50%;\n  left: -15px;\n  display: block;\n  width: 20px;\n  height: 20px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 100%;\n  background-repeat: no-repeat;\n  -webkit-transform: translateY(-50%);\n          transform: translateY(-50%);\n}\n.chat-award__text[data-v-4b229b53] {\n  margin-bottom: 5px;\n  color: #ff5b24;\n  color: var(--color-secondary);\n  font-size: 14px;\n}\n.chat-award__note[data-v-4b229b53] {\n  font-size: 12px;\n}\n", ""]);
// Exports
module.exports = exports;
