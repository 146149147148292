(<template>
  <article class="chat-feedback">
    <div class="chat-feedback__content">
      <p class="chat-feedback__offer-text">{{ feedbackText }}</p>
      <template v-if="userCanSeePrices || $isGodmode()">
        <!-- PRICE TEMPLATES JOBS -->
        <invoice-draft-info v-if="showPriceTemplatePrices"
                            :hide-tips="true"
                            :hide-groups="true"
                            :invoice="offerData"
                            class="chat-feedback__draft-invoice" />
      </template>
    </div>
  </article>
</template>)

<script>
  import InvoiceDraftInfo from '@/components/assignment_components/one_assignment/interactive_info/shared_components/InvoiceDraftInfo';
  import PriceEventPrototype from '@/components/assignment_components/one_assignment/chat_events/PriceEventPrototype';

  export default {
    components: {
      'invoice-draft-info': InvoiceDraftInfo
    },
    extends: PriceEventPrototype,
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      showPriceTemplatePrices() {
        return this.userIsInterpreter || this.$isGodmode();
      },
      feedbackText() { return this.$gettext('Based on feedback'); }
    }
  };
</script>

<style scoped>
  .chat-feedback {
    text-align: center;
  }

  .chat-feedback__offer-text {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .chat-feedback__draft-invoice {
    margin: 10px 0;
  }
</style>
