import { render, staticRenderFns } from "./LanguagesSection.vue?vue&type=template&id=182b3820&scoped=true&"
import script from "./LanguagesSection.vue?vue&type=script&lang=js&"
export * from "./LanguagesSection.vue?vue&type=script&lang=js&"
import style0 from "@assets/css/data_section.css?vue&type=style&index=0&id=182b3820&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "182b3820",
  null
  
)

export default component.exports