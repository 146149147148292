(<template>
  <div class="secretary-message">
    <div v-for="(message, index) in visibleMessages"
         :key="index"
         class="secretary-message__item">
      <p class="secretary-message__title">{{ message.title }}</p>
      <p v-html="message.text"></p>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      messages() {
        return [
          {
            title: this.$gettext('What is a secretarial assignment?'),
            text: this.$gettext('- The interpreter is used to convey shorter messages directly to a non-Norwegian-speaking patient or client. These may include messages related to a doctor\'s appointment(fasting before a blood test, changed appointment, etc.), or to ensure that the patient has received a summons to the appointment.'),
            visible: true,
          },
          {
            title: this.$gettext('How to complete a secretarial assignment?'),
            text: this.$gettext('- Name, telephone number and the message / information to be conveyed will be visible after you have confirmed the assignment.<br>- When the message has been conveyed, you MUST confirm this by clicking on "Click here to finish the assignment".<br>- In some cases, you must also call the customer to confirm that the assignment has been completed. In that case, you will be informed of this in the confirmation you receive before the assignment starts.'),
            visible: false,
          },
        ];
      },
      visibleMessages() {
        if (this.userIsInterpreter) {
          return this.messages;
        } else {
          return this.messages.filter((message) => message.visible);
        }
      },
    }
  };
</script>

<style scoped>
  .secretary-message {
    color: #5c5c5c;
    line-height: 1.43;
  }

  .secretary-message__item {
    margin-bottom: 20px;
    color: #333;
  }

  .secretary-message__item:last-child {
    margin-bottom: 0;
  }

  .secretary-message__title {
    font-weight: bold;
  }
</style>
