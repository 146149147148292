(<template>
  <section :class="{'job-chat--mobile': mobileChatClass,
                    'chat-absolute': textareaFocused}"
           class="job-chat">
    <h2 class="job-chat__conversation-title">
      <button v-if="mobileChatClass"
              class="job-chat__mobile-back-btn"
              @click="closeMobileChat">{{ $gettext('Back') }}</button>
    </h2>
    <p v-if="userCustomWarning"
       class="sk-note job-chat__warning">{{ userCustomWarning }}</p>
    <p v-if="isMessageLimit"
       class="sk-note job-chat__warning">{{ $gettext('The message is too long. Consider shortening it, or sending it in two messages or more.') }}</p>
    <p v-if="isFileLimit"
       class="sk-note job-chat__warning">{{ $gettext('This attachment is too big. Max file size is 50 Mb') }}</p>
    <div :class="{ 'job-chat__external-color': isExternal }"
         class="job-chat__events-list">
      <div ref="eventsCont"
           class="job-chat__events-wrapper">
        <event-wrapper v-for="event in filteredChatEvents"
                       :key="event.notification_identifier"
                       :scroll-pos="scrolledValue"
                       :event-data="event">
          <component :is="computeEventComponent(event.type)"
                     slot="typedEvent"
                     :event-data="event"
                     :past-event="event.pastEvent"
                     :full-data="chatDiscussion"
                     :full-job-data="assignmentObj" />
        </event-wrapper>
      </div>
    </div>
    <form v-if="!hideChat"
          class="job-chat__msg-form"
          @submit.prevent.stop="catchMessage">
      <!-- eslint-disable -->
      <p ref="textarea"
         v-model="message"
         class="job-chat__textarea"
         contenteditable="true"
         @focus="catchFocus"
         @blur="catchBlur"
         @input="catchEdit"
         @keyup.ctrl.enter="catchMessage"
         @keydown.enter="catchMessage"
         v-html="placeholderText"></p>
      <!-- eslint-enable -->
      <label v-if="canUploadFiles"
             class="job-chat__attach-btn">
        <input class="job-chat__file-input"
               type="file"
               refs="fileInput"
               @change="setFile" />
      </label>
      <button class="sk-btn sk-btn--default job-chat__send-bsg-btn">{{ $gettext('Send') }}</button>
    </form>
  </section>
</template>)

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import SimpleMsg from '@/components/assignment_components/one_assignment/chat_events/SimpleMessage';
  import ApplyEvent from '@/components/assignment_components/one_assignment/chat_events/Apply';
  import FeedbackEvent from '@/components/assignment_components/one_assignment/chat_events/Feedback';
  import AwardEvent from '@/components/assignment_components/one_assignment/chat_events/Award';
  import WithdrawEvent from '@/components/assignment_components/one_assignment/chat_events/Withdraw';
  import AbandonEvent from '@/components/assignment_components/one_assignment/chat_events/Abandon';
  import RejectEvent from '@/components/assignment_components/one_assignment/chat_events/Reject';
  import DeclineEvent from '@/components/assignment_components/one_assignment/chat_events/Decline';
  import RevokeEvent from '@/components/assignment_components/one_assignment/chat_events/Revoke';
  import CancelEvent from '@/components/assignment_components/one_assignment/chat_events/Cancel';
  import InviteEvent from '@/components/assignment_components/one_assignment/chat_events/Invite';
  import OvertimeEvent from '@/components/assignment_components/one_assignment/chat_events/Overtime';
  import EventWrapper from '@/components/assignment_components/one_assignment/chat_events/EventWrapper';
  import AccountDelete from '@/components/assignment_components/one_assignment/chat_events/AccountDelete';
  import WSManager from '@/modules/ws_data_module';

  export default {
    components: {
      'chat-simple-message': SimpleMsg,
      'event-wrapper': EventWrapper,
      'chat-apply': ApplyEvent,
      'chat-feedback': FeedbackEvent,
      'chat-award': AwardEvent,
      'chat-withdraw': WithdrawEvent,
      'chat-abandon': AbandonEvent,
      'chat-reject': RejectEvent,
      'chat-decline': DeclineEvent,
      'chat-revoke': RevokeEvent,
      'chat-cancel': CancelEvent,
      'chat-overtime': OvertimeEvent,
      'chat-account-delete': AccountDelete,
      'chat-invite': InviteEvent
    },
    data() {
      return {
        isFileLimit: false,
        messageLimit: 1000,
        message: '',
        scrolled: false,
        textareaFocused: false,
        scrolledValue: '',
        timer: ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCanSendMsgs',
        'userCanSeePrices',
        'userIsNotInterpreter',
        'userCustomWarning'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentObj: (state) => state.storeJobObj || {},
        jobStatus: (state) => state.storeJobObj.status || '',
        assignmentRequirements: (state) => state.storeJobObj.interpretationRequirement || {},
        currentDiscussionId: (state) => state.currentDiscussionId || '',
        businessDiscussions: (state) => state.chatDiscussions.discussions || [],
        currentBusinessDiscussion: (state) => state.currentDiscussion.discussion || {},
        currentInterDiscussion: (state) => state.chatDiscussions.discussion || {},
        mobileChatActive: (state) => state.mobileChatOpened
      }),
      // JOB STATUSES
      isAcceptedJob() { return this.jobStatus === 'accepted'; },
      isUnsuitableJob() { return this.jobStatus === 'unsuitable'; },
      isInProgressJob() { return this.jobStatus === 'in_progress'; },
      isFeedbackJob() { return this.jobStatus === 'needsFeedback'; },
      isCancelledJob() { return this.jobStatus === 'cancelled' || this.jobStatus === 'rejected'; },
      // DISCUSSIONS STATUSES
      isRejected() { return this.chatDiscussion.status === 'rejected'; },
      isAbandoned() { return this.chatDiscussion.status === 'abandoned'; },
      isWithdrawn() { return this.chatDiscussion.status === 'withdrawn'; },
      chatDiscussion() {
        return this.userIsNotInterpreter
          ? this.businessDiscussions.find((discussion) => discussion.id == this.currentDiscussionId) || {}
          : this.currentInterDiscussion;
      },
      placeholderText() {
        return this.message || this.textareaFocused ? '' : `<span class="job-chat__placeholder">${this.placeholder}</span>`;
      },
      placeholder() { return this.$gettext('Type a message...'); },
      buyerCanUseChat() {
        return this.chatDiscussion.demanderInfo?.allowChat !== undefined
          ? this.chatDiscussion.demanderInfo.allowChat
          : true;
      },
      timeIsPassed() {
        return this.$moment().isAfter(this.assignmentRequirements.finishTime);
      },
      userCanDiscuss() {
        return this.isAcceptedJob ? this.currentBusinessDiscussion.status === 'accepted' : true;
      },
      hideBuyerInput() {
        // must return !(true) for show .job-chat__textarea
        return !(this.userCanSendMsgs
          && this.userCanDiscuss
          && this.businessDiscussions.length
          && !this.timeIsPassed);
      },
      hideInterInput() {
        return this.isAbandoned
          || this.isRejected
          || this.isWithdrawn
          || this.timeIsPassed
          || this.isUnsuitableJob
          || !this.buyerCanUseChat;
      },
      hideInput() {
        return this.userIsNotInterpreter
          ? this.hideBuyerInput
          : this.hideInterInput;
      },
      hideChat() {
        return true;
      },
      canShowIntUploadFileBtn() {
        return this.isAcceptedJob
          || this.isInProgressJob
          || this.isFeedbackJob;
      },
      canUploadFiles() { return this.userIsNotInterpreter || this.canShowIntUploadFileBtn; },
      chatEvents() {
        let lastEventIndex = '';
        let lastApplyEventIndex = '';
        const events = this.userIsNotInterpreter
          ? this.currentBusinessDiscussion.events || []
          : this.currentInterDiscussion.events || [];
        for (let i = events.length - 1; i >= 0; i -= 1) {
          const isNotMessage = events[i].type !== 'message';
          const isApplyEvent = events[i].type === 'apply';

          if (isNotMessage && lastEventIndex) {
            events[i].pastEvent = true;
          } else if (isNotMessage) {
            lastEventIndex = i;
          }
          if (isApplyEvent && !lastApplyEventIndex) {
            events[i].lastApplyEvent = true;
            lastApplyEventIndex = i;
          }
        }
        return events;
      },
      filteredChatEvents() {
        return this.chatEvents.filter(({type}) => type && this.showEvent(type));
      },
      globalProgress() {
        return this.$store.state.GlobalStore.mainProgressActive;
      },
      clientWidth() {
        return this.$store.state.GlobalStore.clientWidth;
      },
      isMessageLimit() {
        return this.message.length >= this.messageLimit;
      },
      mobileChatClass() {
        if (this.userIsNotInterpreter) {
          return this.clientWidth < 1025;
        } else {
          return this.clientWidth < 768;
        }
      },
      isExternal() {
        return this.businessDiscussions.length ? this.businessDiscussions[0].supplierInfo.external : '';
      }
    },
    watch: {
      chatEvents() {
        setTimeout(this.scrollToBottom, 0);
      },
      globalProgress() {
        if (!this.globalProgress) {
          setTimeout(this.scrollToBottom, 0);
        }
      }
    },
    methods: {
      ...mapActions('OneAssignmentStore', ['sendFile']),
      catchEdit(eve) {
        if (eve.target.textContent.length >= this.messageLimit) {
          this.$refs.textarea.blur();
          eve.target.textContent = eve.target.textContent.substr(0, this.messageLimit);
        }
        this.message = eve.target.textContent;
      },
      catchFocus() {
        this.textareaFocused = true;
        if (this.mobileChatActive) {
          document.ontouchmove = (event) => {
            event.preventDefault();
          };
        }
      },
      catchBlur() {
        if (!this.message) this.message = this.$refs.textarea ? this.$refs.textarea.textContent : '';
        this.textareaFocused = false;
        if (this.mobileChatActive) {
          document.ontouchmove = null;
        }
      },
      closeMobileChat() {
        this.$store.commit('OneAssignmentStore/closeMobileChat');
      },
      showEvent(eventType) {
        if (eventType === 'feedback') return this.userCanSeePrices;
        return true;
      },
      setFile(eve) {
        const target = eve.target;
        this.isFileLimit = false;
        if (target && target.files && target.files.length) {
          if (target.files[0].size > 52428800) this.isFileLimit = true;
          if (!this.isFileLimit) {
            const form = new FormData();
            form.append('message[text]', '');
            form.append('message[attachment][file]', target.files[0]);
            form.append('message[attachment][name]', target.files[0].name);
            this.sendChatFile(form);
          }
          if (this.$refs.fileInput) {
            this.$refs.fileInput.value = '';
          }
        }
      },
      sendChatFile(form) {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        const params = {
          id: this.chatDiscussion.id,
          form
        };
        this.sendFile(params).then((data) => {
          WSManager.setDataFromSockets(this, data);
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      },
      sendMessage(data) {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        const params = {
          id: this.chatDiscussion.id,
          msg: data
        };
        this.$store.dispatch('OneAssignmentStore/sendMessage', params).then((data) => {
          this.$refs.textarea.blur();
          WSManager.setDataFromSockets(this, data);
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          if (!this.mobileChatActive) this.$refs.textarea.focus();
        }).catch((error) => {
          if (error
            && error.data
            && error.data.errors
            && error.data.errors.length
            && error.data.errors.includes('Unsuitable account status for this action')) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('Your account status is not suitable for this action.')
            });
          }
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      },
      catchMessage() {
        if (!this.message) {
          this.message = this.$refs.textarea && this.$refs.textarea.textContent !== this.placeholder ? this.$refs.textarea.textContent : '';
        }
        if (this.isMessageLimit) return;
        this.message = this.message.trim();
        if (this.message && this.message.length && !this.hideInput) {
          this.sendMessage(this.message);
          this.message = '';
          if (this.$refs.textarea) {
            window.getSelection().removeAllRanges();
            this.$refs.textarea.textContent = '';
          }
        }
      },
      scrollToBottom() {
        if (this.$refs.eventsCont && this.$refs.eventsCont.getBoundingClientRect().width !== 0 && this.$refs.eventsCont.lastChild) {
          this.scrolled = true;
          this.$refs.eventsCont.scrollTop = 9999999999999;
          if (this.$refs.eventsCont.lastChild.scrollIntoView) {
            this.$refs.eventsCont.lastChild.scrollIntoView(false); // scroll to last message for IE
          }
          this.scrolledValue = this.$refs.eventsCont.scrollTop;
        }
      },
      updateScrollPos() {
        clearInterval(this.timer);
        this.timer = setTimeout(() => {
          if (this.$refs.eventsCont) {
            this.scrolledValue = this.$refs.eventsCont.scrollTop;
          }
        }, 500);
      },
      computeEventComponent(type) {
        switch (type) {
          case 'message':
            return 'chat-simple-message';
          case 'apply':
            return 'chat-apply';
          case 'feedback':
            return 'chat-feedback';
          case 'award':
          case 'auto_award':
            return 'chat-award';
          case 'withdraw':
            return 'chat-withdraw';
          case 'decline':
            return 'chat-decline';
          case 'revoke':
            return 'chat-revoke';
          case 'reject':
            return 'chat-reject';
          case 'abandon':
            return 'chat-abandon';
          case 'invite':
          case 'direct_invite':
            return 'chat-invite';
          case 'overtime':
            return 'chat-overtime';
          case 'cancel':
            return 'chat-cancel';
          case 'account_delete':
            return 'chat-account-delete';
        }
      }
    },
    beforeMount() {
      this.$store.commit('clearChatWrapperPos');
    },
    mounted() {
      setTimeout(this.scrollToBottom, 0);
      if (this.$refs.eventsCont) {
        this.$refs.eventsCont.addEventListener('scroll', this.updateScrollPos);
      }
    },
    updated() {
      if (this.$refs.eventsCont) {
        this.$store.commit('setChatWrapperPos', this.$refs.eventsCont.getBoundingClientRect().top);
      }
      if (!this.scrolled) {
        setTimeout(this.scrollToBottom, 0);
      }
    }
  };
</script>

<style>
  .job-chat__placeholder {
    color: #646d8c;
  }
</style>

<style scoped>
  .job-chat {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: calc(100% - 285px);
    min-height: 120px;
    border-radius: 3px;
    background-color: #fff;
  }

  .job-chat--mobile.chat-absolute {
    position: absolute;
    height: calc(100% + 95px);
    margin-top: -45px;
  }

  .job-chat--mobile {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    width: 100%;
  }

  .job-chat__mobile-title {
    overflow: auto;
    margin: 0 50px;
    text-align: center;
  }

  .job-chat__conversation-title {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;
    min-height: 45px;
    padding: 15px 10px 10px;
    border-bottom: solid 1px #e9eaef;
    font-size: 14px;
  }

  .job-chat__mobile-back-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    display: block;
    padding-left: 15px;
    font-size: 12px;
    line-height: 45px;
  }

  .job-chat__mobile-back-btn::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 15px;
    background-image: url(~@assets/imgs/arrows/big_arrow_to_left.svg);
    background-position: 50% 50%;
    background-size: 10px auto;
    background-repeat: no-repeat;
  }

  .job-chat__warning {
    display: flex;
    width: 100%;
    padding: 5px 8px;
    background-color: #fff5d1;
    font-size: 10px;
  }

  .job-chat__warning::before {
    position: relative;
    margin-right: 10px;
  }

  .job-chat__events-list {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
    overflow: auto;
    width: 100%;
    max-height: 100%;
  }

  .job-chat__events-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
  }

  .job-chat__events-list:empty::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    min-width: 120px;
    max-width: 100%;
    min-height: 120px;
    max-height: 100%;
    background-image: url(~@assets/imgs/undefined_imgs/translation_icon_gray.svg);
    background-position: 50% 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
  }

  .job-chat__msg-form {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: 100%;
    padding: 6px 20px 15px;
    border-top: solid 1px #e9eaef;
  }

  .job-chat__textarea {
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;
    max-width: calc(100% - 75px);
    min-height: 30px;
    max-height: 230px;
    border: 0;
    white-space: pre-wrap;
    word-break: break-all;
  }

  .job-chat__attach-btn {
    display: block;
    width: 35px;
    height: 30px;
    border: solid 1px #a7abbd;
    border-radius: 3px;
    background-image: url(~@assets/imgs/undefined_imgs/paperclip_icon.svg);
    background-image: var(--image-paperclip-icon);
    background-position: 50% 50%;
    background-size: 16px auto;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .job-chat__attach-btn:hover {
    background-color: #e9eaef;
  }

  .job-chat__attach-btn:active {
    background-color: #d3d5de;
  }

  .job-chat__file-input {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  .job-chat__send-bsg-btn {
    display: inline-block;
    flex-shrink: 0;
    width: auto;
    height: 30px;
    margin-left: 5px;
    padding: 0 10px;
    border-radius: 3px;
    line-height: 30px;
  }

  .job-chat__external-color {
    background-color: rgba(153, 221, 221, 0.2);
  }
</style>
