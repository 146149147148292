(<template>
  <article class="chat-account-delete">
    <div class="chat-account-delete__content">
      <p class="chat-account-delete__text">{{ accountDeleteText }}</p>
    </div>
  </article>
</template>)

<script>
  export default {
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      },
      fullData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      accountDeleteText() {
        return this.$gettext('This account has been deleted.');
      }
    }
  };
</script>

<style scoped>
  .chat-account-delete__text {
    color: #f04;
    color: var(--color-error);
    font-size: 14px;
  }
</style>
