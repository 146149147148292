(<template>
  <article class="chat-abandon">
    <div v-if="userIsInterpreter"
         class="chat-abandon__content">
      <p class="chat-invite__text"
         v-html="interpreterInviteText"></p>
    </div>
    <div v-else
         class="chat-abandon__content">
      <p class="chat-invite__text"
         v-html="businessInviteText"></p>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      },
      fullData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters({
        userIsInterpreter: 'UserInfoStore/userIsInterpreter'
      }),
      businessInviteText() {
        const template = this.$gettext('%{n} has been invited to this assignment.');
        return this.$gettextInterpolate(template, {n: this.fullData && this.fullData.supplierInfo ? this.fullData.supplierInfo.name : ''});
      },
      interpreterInviteText() {
        const template = this.$gettext('You have been invited to this assignment. Please respond by applying or hiding this request.');
        return this.$gettextInterpolate(template, {n: this.fullData && this.fullData.demanderInfo ? this.fullData.demanderInfo.name : ''});
      }
    }
  };
</script>

<style scoped>
  .chat-invite__text {
    margin-bottom: 5px;
    color: #f93;
    font-size: 14px;
  }
</style>
