(<template>
  <section class="attenders-section attenders-section--selected-preview">
    <h2 class="attenders-section__title">{{ $gettext('Attender(s)') }}</h2>
    <div class="attenders-section__wrapper">
      <div v-for="(att, index) in chosenAttenders"
           :key="index"
           class="attenders-section__attender-cont">
        <p class="attenders-section__attender-name">{{ att.firstName || att.email }}</p>
        <div class="attenders-section__preview"
             v-html="getPreviewAttender(att.uid)"></div>
      </div>
      <button v-if="userCanManageJob"
              class="add-plus-btn"
              @click="editAttendersList"></button>
    </div>
  </section>
</template>)

<script>
  import helpers from '@/helpers';
  import {mapState, mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', ['userCanManageJob']),
      ...mapState('OneAssignmentStore', {
        assignmentRequirements: (state) => state.storeJobObj.interpretationRequirement || {}
      }),
      enterpriseDepartment() { return this.assignmentRequirements.department || {}; },
      chosenAttenders() { return this.assignmentRequirements.attenders || []; }
    },
    methods: {
      getPreviewAttender(attenderId) {
        const selectedItem = this.chosenAttenders.find((item) => item.uid == attenderId) || {};
        return helpers.getSelectedPreview(selectedItem);
      },
      editAttendersList() {
        this.$store.commit('ModalStore/setModal', {
          component: 'attenders-list',
          data: {
            department: this.enterpriseDepartment,
            attenders: JSON.parse(JSON.stringify(this.chosenAttenders)),
            title: this.$gettext('Add more attenders to your assignment'),
            context: this
          }
        });
      },
      sendNewAttenders(list) {
        const jobId = this.$route.params.id;
        const params = {jobID: jobId, array: list};
        this.$store.dispatch('OneAssignmentStore/saveNewAttendersList', params).then(() => {
          this.$store.commit('ModalStore/removeModal');
        }).catch((error) => {
          this.$emit('stopprogress');
          this.$scrollToErrors();
          this.$store.commit('ModalStore/setModalErrors', error);
        });
      }
    }
  };
</script>

<style>
  .attenders-section__preview .preview {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
  }
</style>

<style scoped>
  .attenders-section {
    display: block;
    width: 100%;
  }

  .attenders-section__preview,
  .attenders-section__attender-name {
    line-height: 1;
    word-break: break-all;
  }

  .attenders-section--selected-preview .attenders-section__wrapper {
    justify-content: flex-end;
  }

  .attenders-section--selected-preview .attenders-section__attender-cont {
    align-items: flex-start;
    width: 100%;
  }

  .attenders-section--selected-preview .attenders-section__attender-name {
    text-align: left;
  }

  .attenders-section__title {
    display: block;
    color: #5a5964;
    font-weight: normal;
    font-size: 10px;
  }

  .attenders-section__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .attenders-section__wrapper > *:not(:last-child):not(:only-child) {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .attenders-section__attender-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
  }

  .attenders-section__attender-name {
    display: block;
    overflow: hidden;
    width: 100%;
    font-weight: bold;
    text-align: center;
    text-overflow: ellipsis;
  }
</style>
