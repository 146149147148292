(<template>
  <div class="change-notify-section">
    <section v-for="unit in data.data"
             :key="unit.title"
             class="change-notify-section__wrapper">
      <p class="change-notify-section__data"
         v-html="unit.data"></p>
      <p v-if="data.btnInfoText"
         class="change-notify-section__data change-notify-section__btn-info">{{ data.btnInfoText }}</p>
      <button v-if="data.btnText"
              class="sk-btn sk-btn--white change-notify-section__button"
              @click="confirmRequirement">{{ data.btnText }}</button>
    </section>
  </div>
</template>)

<script>
  export default {props: {
                    data: {
                      type: Object,
                      default() {
                        return {};
                      }
                    }
                  },
                  methods: {
                    confirmRequirement() {
                      this.$store.commit('ModalStore/setModal', {
                        component: 'confirmation-modal',
                        width: 410,
                        classes: ['sk-modal--new'],
                        data: {
                          title: this.$gettext('Are you sure you want to turn off this requirement?'),
                          text: '',
                          context: this,
                          modalCallback: this.data.btnAction,
                          btnTexts: {
                            actionBtnText: this.$gettext('Confirm'),
                            cancelBtnText: this.$gettext('Cancel')
                          }
                        }
                      });
                    }
                  }};
</script>

<style scoped>
  .change-notify-section {
    display: flex;
    width: 100%;
    margin-top: -10px;
  }

  .change-notify-section__wrapper {
    flex-grow: 1;
    max-width: 100%;
  }

  .change-notify-section__data {
    display: flex;
    flex-direction: column;
    color: #b68e56;
    font-size: 14px;
  }

  .change-notify-section__button,
  .change-notify-section__btn-info {
    margin-top: 5px;
  }
</style>
