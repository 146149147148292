(<template>
  <section :class="{'chat-inters-list--mobile': mobileListClass}"
           class="chat-inters-list">
    <h2 v-if="showListTitle"
        class="chat-inters-list__title">
      <span>{{ $gettext('Message with interpreters') }}</span>
    </h2>
    <template v-if="isAwardedJob">
      <ul class="chat-inters-list__list"
          :data-empty-text="emptyText">
        <interpreter v-for="item in confirmedDiscussion"
                     :key="item.id"
                     :discussion="item" />
        <li v-if="otherDiscussions && otherDiscussions.length">
          <button class="chat-inters-list__toggle-btn"
                  :class="{'is-opened': openedOtherDiscussions}"
                  @click="toggleOtherDiscussions">{{ $gettext('Other discussions') }}</button>
        </li>
        <template v-if="openedOtherDiscussions">
          <interpreter v-for="discussion in otherDiscussions"
                       :key="discussion.id"
                       :discussion="discussion" />
        </template>
      </ul>
    </template>
    <template v-else>
      <ul class="chat-inters-list__list"
          :data-empty-text="emptyText">
        <interpreter v-for="discussion in businessDiscussions"
                     :key="discussion.id"
                     :discussion="discussion" />
      </ul>
    </template>
  </section>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import ListInterpreter from '@/components/assignment_components/one_assignment/chat/InterpreterForChatList';

  export default {
    components: {
      interpreter: ListInterpreter
    },
    data() {
      return {
        openedOtherDiscussions: false
      };
    },
    computed: {
      ...mapGetters({
        userIsNotInterpreter: 'UserInfoStore/userIsNotInterpreter'
      }),
      ...mapState('OneAssignmentStore', {
        justInvited: (state) => state.justInvited,
        currentDiscussionId: (state) => state.currentDiscussionId,
        assignmentRequirements: (state) => state.storeJobObj.interpretationRequirement || {}
      }),
      finishTimePassed() {
        return this.$moment().isAfter(this.assignmentRequirements.finishTime);
      },
      emptyText() {
        return this.$gettext('Interpreter names will appear here');
      },
      businessDiscussions() {
        const discussions = [...this.$store.state.OneAssignmentStore.chatDiscussions.discussions || []];
        discussions.sort((a, b) => {
          if (this.$moment(b.keyEvent.date).isAfter(this.$moment(a.keyEvent.date))) {
            return 1;
          }
          if (this.$moment(a.keyEvent.date).isAfter(this.$moment(b.keyEvent.date))) {
            return -1;
          }
          return 0;
        });
        for (let i = 0, lim = discussions.length; i < lim; i += 1) {
          if (discussions[i].status == 'accepted') {
            const acceptedDiscussion = discussions.splice(i, 1);
            discussions.unshift(acceptedDiscussion[0]);
            break;
          }
        }
        if (this.justInvited) {
          this.setInterpreter(discussions);
        }
        return discussions;
      },
      isDiscussions() {
        return this.businessDiscussions.length > 0;
      },
      isAwardedJob() {
        return this.businessDiscussions && this.confirmedDiscussion.length > 0;
      },
      confirmedDiscussion() {
        return this.businessDiscussions.filter((item) => item.status === 'accepted');
      },
      otherDiscussions() {
        return this.businessDiscussions.filter((item) => item.status !== 'accepted');
      },
      showListTitle() {
        return this.mobileListClass ? this.isDiscussions : true;
      },
      clientWidth() {
        return this.$store.state.GlobalStore.clientWidth;
      },
      mobileListClass() {
        if (this.userIsNotInterpreter) {
          return this.clientWidth < 1025;
        } else {
          return this.clientWidth < 768;
        }
      }
    },
    methods: {
      toggleOtherDiscussions() {
        this.openedOtherDiscussions = !this.openedOtherDiscussions;
      },
      setInterpreter(discussions) {
        let count = 0;
        for (let i = 0; i < discussions.length; i += 1) {
          if (discussions[i].supplierInfo && discussions[i].supplierInfo.uid === this.justInvited) {
            count = i;
            break;
          }
        }
        if (discussions[count]) {
          if (this.clientWidth > 1024 && +this.currentDiscussionId === +discussions[count].id) return;
          this.$store.commit('OneAssignmentStore/startAssignmentProgress');
          this.$store.dispatch('OneAssignmentStore/getOneDiscussion', discussions[count].id).then(() => {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
            this.$store.commit('OneAssignmentStore/setJustInvited', '');
            if (this.userIsInterpreter && this.clientWidth < 768) {
              this.$store.commit('OneAssignmentStore/openMobileChat');
            } else if (!this.userIsInterpreter && this.clientWidth < 1025) {
              this.$store.commit('OneAssignmentStore/openMobileChat');
            }
          });
        }
      }
    }
  };
</script>

<style scoped>
  .chat-inters-list {
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 285px;
    border-right: 1px solid #e9ebef;
    background-color: #fff;
  }

  .chat-inters-list--mobile {
    width: 100%;
  }

  .chat-inters-list__title {
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    width: 100%;
    padding: 15px 10px 10px 30px;
    border-bottom: solid 1px #e9eaef;
    font-size: 14px;
  }

  .chat-inters-list__list {
    position: relative;
    display: block;
    flex-grow: 1;
    overflow: auto;
    width: 100%;
    min-height: 40px;
    list-style-type: none;
  }

  .chat-inters-list__list:empty::before {
    content: attr(data-empty-text);
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    display: block;
    padding: 0 40px;
    color: #a7abbd;
    font-size: 16px;
    text-align: center;
    transform: translateY(-50%);
  }

  .chat-inters-list__toggle-btn {
    position: relative;
    width: 100%;
    height: 35px;
    padding: 0 30px;
    border-bottom: 1px solid #e9ebef;
    color: #ff5b24;
    color: var(--color-secondary);
    text-align: left;
  }

  .chat-inters-list__toggle-btn::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 7px;
    box-sizing: border-box;
    width: 7px;
    height: 1px;
    background-color: #ff5b24;
    background-color: var(--color-secondary);
    transform: rotate(-43deg);
  }

  .chat-inters-list__toggle-btn::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    box-sizing: border-box;
    width: 7px;
    height: 1px;
    background-color: #ff5b24;
    background-color: var(--color-secondary);
    transform: rotate(43deg);
  }

  .chat-inters-list__toggle-btn.is-opened::after {
    transform: rotate(43deg);
  }

  .chat-inters-list__toggle-btn.is-opened::before {
    transform: rotate(-43deg);
  }
</style>
