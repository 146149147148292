(<template>
  <article class="chat-withdraw">
    <div v-if="userIsInterpreter"
         class="chat-withdraw__content">
      <p class="chat-withdraw__text">{{ interpreterWithdrawText }}</p>
    </div>
    <div v-else
         class="chat-withdraw__content">
      <p class="chat-withdraw__text">{{ businessWithdrawText }}</p>
    </div>
    <div v-if="isSupplierWithdraw && !userIsInterpreter"
         class="chat-withdraw__content">
      <p class="chat-withdraw__text">{{ reasonWithdrawText }}</p>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      },
      fullData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        notes: this.fullData?.notes
      };
    },
    computed: {
      ...mapGetters({
        userIsInterpreter: 'UserInfoStore/userIsInterpreter'
      }),
      isSupplierWithdraw() {
        return this.notes?.[0]?.type === 'supplier_note';
      },
      interpreterWithdrawText() {
        return this.$gettext('You have withdrawn from this assignment');
      },
      businessWithdrawText() {
        const template = this.$gettext('%{n} has withdrawn from this assignment.');
        return this.$gettextInterpolate(template, {n: this.fullData && this.fullData.supplierInfo ? this.fullData.supplierInfo.name : ''});
      },
      reasonWithdrawText() {
        const template = this.$gettext('Interpreter message: "%{n}"');
        return this.$gettextInterpolate(template, {n: this.notes[0].note});
      }
    }
  };
</script>

<style scoped>
  .chat-withdraw__text {
    margin-bottom: 5px;
    color: #f04;
    font-size: 14px;
  }
</style>
