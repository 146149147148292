// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/download-ico.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.chat-simple-msg[data-v-5fe70a7d] {\n  word-break: break-all;\n}\n.chat-attachment__download[data-v-5fe70a7d] {\n  margin-bottom: 5px;\n  font-weight: normal;\n}\n.chat-attachment__download[data-v-5fe70a7d]::after {\n  content: '';\n  display: inline-block;\n  vertical-align: bottom;\n  width: 16px;\n  height: 16px;\n  margin: 2px 0 2px 5px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 16px auto;\n  background-repeat: no-repeat;\n}\n@media (max-width: 500px) {\n.chat-simple-msg__file[data-v-5fe70a7d] {\n    margin: 0 -10px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
