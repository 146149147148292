(<template>
  <section class="manager-section">
    <div class="manager-section__wrapper">
      <p class="manager-section__title">{{ sectionConfig.title }}</p>
      <tt-avatar :class="['manager-section__avatar--salita']"
                 class="manager-section__avatar" />
      <div class="manager-section__contacts">
        <p class="manager-section__name">{{ sectionConfig.managerName }}</p>
        <span class="sk-link manager-section__contact"
              v-html="sectionConfig.email"></span>
        <span class="sk-link manager-section__contact"
              v-html="sectionConfig.phone"></span>
      </div>
    </div>
  </section>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', ['currentProvider']),
      sectionConfig() {
        const domain = this.$getString('domainData', 'platformName');
        const config = {title: `${domain} Kundesenter`, managerName: 'Linda Lunder'};
        return {...config, ...{email: this.email, phone: this.phone}};
      },
      phone() {
        const template = this.$gettext('Tel: <a href="tel:%{tel}" class="sk-link js-copy-to-clipboard">%{tel}</a>');
        return this.$gettextInterpolate(template, {tel: this.$getString('domainData', 'supportPhoneText')});
      },
      email() {
        const template = this.$gettext('Email: <a href="mailto:%{email}" class="sk-link js-copy-to-clipboard">%{email}</a>');
        return this.$gettextInterpolate(template, {email: this.$getString('domainData', 'subSupportEmail')});
      }
    }
  };
</script>

<style scoped>
  .sk-link,
  .sk-link--default {
    font-weight: bold;
    font-size: 13px;
  }

  .manager-section__wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 3px;
    background-color: #f4f5f7;
  }

  #app.salita-facelifted .manager-section__wrapper {
    border-radius: 4px;
  }

  .manager-section__title {
    width: 100%;
    padding-bottom: 5px;
    color: #333;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }

  .manager-section__contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
  }

  .one-assignment .tt-avatar.manager-section__avatar {
    width: 51px;
    height: 51px;
  }

  .one-assignment .tt-avatar.manager-section__avatar--salita {
    background-image: url(~@assets/imgs/undefined_imgs/interpretation_account_manager_salita.png);
  }
</style>
