(<template>
  <div class="attachments-section">
    <div v-for="(file, index) in uploadedFiles"
         :key="index"
         class="sk-upload-file__label-btn attachments-section__file-cont">
      <p class="attachments-section__file-name">{{ file.comment || '---' }}</p>
      <button class="sk-link"
              @click="decriptFile(file.file)">{{ $gettext('Download') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('OneAssignmentStore', {
        assignmentObj: (state) => state.storeJobObj || {}
      }),
      uploadedFiles() { return this.assignmentObj.attachments; }
    },
    methods: {
      decriptFile(link) {
        this.$store.dispatch('decriptFile', link).then((newLink) => {
          window.location.href = newLink;
        });
      }
    }
  };
</script>

<style scoped>
  .attachments-section {
    display: block;
    width: 100%;
    font-size: 12px;
  }

  .attachments-section__file-cont {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 30px;
  }

  .attachments-section__file-cont::before {
    content: '';
    top: 0;
    left: 0;
    background-size: 22px auto;
  }
</style>
