<template>
  <article class="inter-list">
    <div v-for="interpreter in interpreters"
         :key="interpreter.uid">
      <router-link :to="getProfileLink(interpreter.uid)"
                   target="_blank"
                   class="interpreters-list__tile">
        <interpreter-avatar :interpreter="interpreter"
                            class="inter-avatar" />
        <div class="replacement-interpreter__info">
          <div class="replacement-interpreter__info-item">
            <label class="replacement-interpreter__info-label">{{ $gettext('Name:') }}</label>
            <p>{{ interpreter.name }}</p>
          </div>
          <div class="replacement-interpreter__info-item">
            <label class="replacement-interpreter__info-label">{{ $gettext('Phone number:') }}</label>
            <p class="js-copy-to-clipboard">{{ interpreter.phone }}</p>
          </div>
        </div>
      </router-link>

    </div>
  </article>
</template>

<script>
  import Interpreter from '@/components/interpreters_components/InterForDashboardList';

  export default {
    components: {
      'interpreter-avatar': Interpreter
    },
    props: {
      interpreters: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      getProfileLink(uid) {
        return this.$makeRoute({name: 'BuyerUserProfile', params: {id: uid}});
      }
    }
  };
</script>

<style scoped>
  .interpreters-list__tile {
    display: flex;
    margin: 10px 10px 10px 0;
    border-bottom: 1px solid #e9eaef;
  }

  .inter-avatar {
    max-width: 100px;
  }

  .replacement-interpreter__info {
    display: block;
  }

  .replacement-interpreter__info-item {
    display: flex;
  }

  .replacement-interpreter__info-label {
    margin-right: 5px;
  }
</style>
