(<template>
  <article :class="{'chat-event--own': own, 'chat-event--message': isSimpleMessage}"
           class="chat-event">
    <div v-if="withAvatar"
         class="chat-event__avatar-wrapper">
      <person-avatar :interpreter="interAvatar"
                     :is-chat="true" />
    </div>
    <div :class="{'chat-message--own': own && isMessage,
                  'chat-message--other': isSimpleMessage}"
         class="chat-message">
      <slot name="typedEvent"></slot>
    </div>
    <p class="chat-event__event-date">{{ eventDate }}</p>
  </article>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';
  import Interpreter from '@/components/interpreters_components/InterForDashboardList';

  export default {
    components: {
      'person-avatar': Interpreter
    },
    props: {
      eventData: {
        type: Object,
        default() {
          return {};
        }
      },
      scrollPos: {
        type: [String, Number],
        default: ''
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', {
        userIsInterpreter: 'userIsInterpreter'
      }),
      ...mapState('OneAssignmentStore', {
        chatDiscussion: (state) => {
          return state.chatDiscussions && state.chatDiscussions.discussion ? state.chatDiscussions.discussion : {};
        },
        currentDiscussionId: (state) => {
          return state.currentDiscussionId;
        }
      }),
      own() {
        return this.eventData.own;
      },
      isMessage() {
        return this.eventData.type === 'message';
      },
      jobId() {
        return this.$route.params.id;
      },
      online() {
        return this.chatDiscussion.demanderInfo && this.chatDiscussion.demanderInfo.online;
      },
      discId() {
        return this.userIsInterpreter ? this.chatDiscussion.id : this.currentDiscussionId;
      },
      eventDate() {
        if (this.eventData) {
          return this.$moment(this.eventData.date).format('DD MMM HH:mm');
        }
      },
      isSimpleMessage() {
        return this.eventData ? this.eventData.type == 'message' : '';
      },
      withAvatar() {
        return this.isSimpleMessage && this.interAvatar && this.interAvatar.avatar;
      },
      interAvatar() {
        return {avatar: this.eventData.avatar, online: this.own || this.online};
      },
      chatWrapperPos() {
        return this.$store.state.chatWrapperPos;
      }
    }
  };
</script>

<style>
.chat-event__avatar-wrapper > * {
  width: 100%;
  height: 100%;
}
</style>

<style scoped>
  .chat-event {
    position: relative;
    display: flex;
    flex: 0 1 100%; /* important for ie! */
    align-items: flex-end;
    width: 100%;
    padding: 10px 80px 15px 20px;
    text-align: left;
  }

  .chat-event--own {
    flex-direction: row-reverse;
    padding: 10px 20px 15px 80px;
    text-align: right;
  }

  .chat-event__avatar-wrapper {
    position: relative;
    display: block;
    flex-shrink: 0;
    align-self: flex-end;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .chat-event--own .chat-event__avatar-wrapper {
    margin-right: 0;
    margin-left: 10px;
  }

  .chat-event__avatar {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    margin-bottom: 5px;
  }

  .chat-event__user-online-indicator {
    position: absolute;
    top: 22px;
    right: 0;
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
    background-color: #ff5b24;
    background-color: var(--color-secondary);
  }

  .chat-event__event-date {
    position: absolute;
    bottom: 0;
    left: 20px;
    font-size: 10px;
    text-align: center;
    opacity: 0.5;
  }

  .chat-event--own .chat-event__event-date {
    right: 20px;
    left: auto;
  }

  .chat-event__content-wrapper {
    position: relative;
    display: block;
    flex-grow: 0;
    width: auto;
    max-width: 100%;
    padding: 10px 25px;
    border: solid 1px #e9eaef;
    border-radius: 10px;
    text-align: left;
  }

  .chat-event--message .chat-event__content-wrapper {
    border: none;
    background-color: #f4f5f7;
  }

  .chat-event--own.chat-event--message .chat-event__content-wrapper {
    background-color: #cee;
  }

  @media (max-width: 550px) {
    .chat-event {
      padding: 10px 60px 15px 20px;
    }

    .chat-event--own {
      padding: 10px 20px 15px 60px;
    }
  }
</style>
